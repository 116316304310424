import Map from "../components/Map";
import styled from "styled-components";
import NavBar from "../components/Navbar";
import PhotoGallery from "../components/PhotoGallery";
import LeftRightSlideContainer from "../components/LeftRightSlideContainer";
import houses from "./HouseInfo";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import { PrimaryButton } from "../styledComponents";

const HomeContainer = styled.div`
  height: auto;
  width: 100%;
`;

const WelcomeContainer = styled.div`
  height: 95vh;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center; // Centers the image horizontally
  align-items: center; // Centers the image vertically

  img {
    width: 700px; // Maximum width of the image is 80% of its container
    height: 500px; // Maximum height of the image is 80% of its container
    object-fit: cover; // Ensures the image does not get distorted
  }

  @media (max-width: 768px) {
    img {
      width: 100%;
      height: 100%;
    }
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

type InfoContainerProps = {
  alignRight?: boolean;
};

const InfoContainer = styled.div<InfoContainerProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem;

  flex-direction: column;
  h1,
  h2,
  p {
    text-align: ${(props) => (props.alignRight ? "right" : "left")};
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 2rem;
    p,
    h1 {
      text-align: left;
    }
  }
`;

const Header = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily};
  margin-bottom: 0rem;
`;

const SubTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.textColors.secondary};
  margin-top: 0rem;
  font-size: 1rem;
`;

const BodyText = styled.p`
  font-family: ${({ theme }) => theme.fontFamily};
`;

type HouseInfoProps = {
  tagline: string;
  description: string;
  alignRight?: boolean; // Add this line
  route: string;
  propertyId: string;
};

const HouseInfo: React.FC<HouseInfoProps> = ({
  tagline,
  description,
  alignRight,
  route,
  propertyId,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <InfoContainer alignRight={alignRight}>
      <Header>{tagline}</Header>
      <SubTitle>Property ID: {propertyId}</SubTitle>
      <BodyText>{description}</BodyText>

      <StyledLink to={route}>
        <PrimaryButton color="#000">Booking Details</PrimaryButton>
      </StyledLink>
    </InfoContainer>
  );
};

type FlagPhotoProps = {
  src: string;
  alt: string;
};

function FlagPhoto(props: FlagPhotoProps) {
  const { src, alt } = props;
  return (
    <ImageContainer>
      <img src={src} alt={alt} />
    </ImageContainer>
  );
}

function Home() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <HomeContainer>
      <NavBar />

      <WelcomeContainer>
        <PhotoGallery />
      </WelcomeContainer>

      {windowWidth > 768
        ? houses.map((house, index) =>
            index % 2 === 0 ? (
              <LeftRightSlideContainer
                LeftSide={HouseInfo({
                  tagline: house.tagline,
                  description: house.description,
                  alignRight: true,
                  route: house.route,
                  propertyId: house.propertyId,
                })}
                RightSide={FlagPhoto({ src: house.flagPhoto, alt: "alt" })}
              />
            ) : (
              <LeftRightSlideContainer
                LeftSide={FlagPhoto({ src: house.flagPhoto, alt: "alt" })}
                RightSide={HouseInfo({
                  tagline: house.tagline,
                  description: house.description,
                  alignRight: false,
                  route: house.route,
                  propertyId: house.propertyId,
                })}
              />
            )
          )
        : houses.map((house, index) => (
            <LeftRightSlideContainer
              LeftSide={HouseInfo({
                tagline: house.tagline,
                description: house.description,
                alignRight: false,
                route: house.route,
                propertyId: house.propertyId,
              })}
              RightSide={FlagPhoto({ src: house.flagPhoto, alt: "alt" })}
            />
          ))}
      <Map />
    </HomeContainer>
  );
}

export default Home;
