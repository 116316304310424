import { Title, SubTitle, BodyText } from "../../styledTexts";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS for react-datepicker
import { useState, useEffect } from "react";
import { GoDotFill } from "react-icons/go";
import { Calendar } from "primereact/calendar";
import "./custom-calendar.css";
import * as Styled from "./styles";
import styled from "styled-components";
import FadeInSection from "../../FadeInSection";
import NavBar from "../Navbar";
import ContactCard from "../ContactCard/ContactCard";
import { ToastContainer } from "react-toastify";
import { MdOutlinePets } from "react-icons/md";
import { TbSmokingNo } from "react-icons/tb";
import { RiLoginBoxLine } from "react-icons/ri";
import { RiLogoutBoxLine } from "react-icons/ri";
import { ImCancelCircle } from "react-icons/im";
import { SlidingButton, SubmitButton } from "../../styledComponents";
import PhotoGroup from "../PhotoGroup/PhotoGroup";
import { BiSolidGrid } from "react-icons/bi";
import CalendarWithPrices from "../Calendar/Calendar";
import { PropertyAPI } from "../../api";
import PropertyType from "../../types/PropertyType";

interface PropertyProps {
  [key: string]: any;
}

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
`;

interface ModalContentProps {
  width?: string;
  height?: string;
}

const ModalContent = styled.div<ModalContentProps>`
  position: fixed;
  // top: 50%;
  // left: 50%;
  transform: translate(-50%, -50%); /* Center the modal */
  // max-width: 90vw; /* Maximum width of modal */
  overflow-y: auto; /* Enables scrolling if content overflows */
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 15px;
  padding: 10px 30px 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1001; /* Even higher z-index */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  height: ${({ height }) => (height ? height : "80vh")};
  width: ${({ width }) => (width ? width : "70vw")};
`;

const ModalPhotoContent = styled.div`
  width: 100%;
`;

const ModalPhoto = styled.img`
  width: 100%;
`;

const StyledImg = styled.img`
  cursor: pointer;

  &:hover {
    filter: brightness(75%);
  }
`;

const ModalPhotoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const PhotoGalleryButton = styled(SlidingButton)`
  position: absolute;
  z-index: 3;
  bottom: 10px;
  right: 10px;
  align-items: center;
`;

const ButtonSpan = styled.span`
  display: flex;
  align-items: center; // Center align vertically
  justify-content: center; // Center align horizontally (if needed)
`;

const StyledIcon = styled(BiSolidGrid)`
  margin-right: 8px; // Adjust the space between the icon and the text
  align-self: center; // Ensure vertical center alignment
  justify-self: center; // Ensure horizontal center alignment
`;

// const BookNowSection = styled.div`
//   display: none;
//   width: 100%;
//   background-color: ${({ theme }) => theme.backgroundColors.primary};
//   @media (max-width: 768px) {
//     display: block;
//   }
//   height: 80px;
//   position: sticky;
//   bottom: 0;
// `;

const BookNowSection = styled.div<{ isExtended: Boolean }>`
  width: 99%;
  padding-bottom: 10px;
  background-color: ${({ theme }) => theme.backgroundColors.primary};
  position: sticky;
  margin-bottom: 10px;
  bottom: 0;
  z-index: 4;
  transition: height 0.3s ease-in-out;
  display: ${({ isExtended }) =>
    isExtended ? "block" : "none"}; // Adjust based on your requirements
  height: ${({ isExtended }) =>
    isExtended ? "800px" : "80px"}; // Adjust height as needed

  @media (max-width: 768px) {
    display: block;
  }
`;

interface dateTemplateProps {
  day: number;
  month: number;
  year: number;
  otherMonth?: boolean;
  today?: boolean;
  selectable?: boolean;
}

const Property: React.FC<PropertyProps> = (props) => {
  // const [showAllPayPeriods, setShowAllPayPeriods] = useState<boolean>(false); // Start with false
  const [showModal, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [property, setProperty] = useState<PropertyType | null>(null);
  const [isExtended, setIsExtended] = useState(false);

  const toggleExtension = () => {
    setIsExtended(!isExtended);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const property1 = await PropertyAPI.getProperty(props.propertyId);
        setProperty(property1);
      } catch (error) {
        console.error("Error fetching property data:", error);
      }
    };

    fetchData();
  }, []);
  // console.log("property", property);

  // const [property, setProperty] = useState<PropertyType | null>(null);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const property1 = await PropertyAPI.getProperty(props.id);
  //       console.log(property1);
  //       setProperty(property1);
  //     } catch (error) {
  //       console.error("Error fetching property data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const handleClose = () => {
    setIsModalOpen(false); // Hide modal on close
  };

  const handlePhotoOpen = () => {
    setShowPhotoModal(true);
  };

  function dateTemplate(date: dateTemplateProps) {
    // Create a new date object for today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Create a new date object for the date to check
    const checkDate = new Date(date.year, date.month, date.day);

    if (checkDate < today) {
      // If the date is before today, apply the crossing-out style
      return (
        <div
          style={{
            textDecoration: "line-through",
          }}
        >
          {date.day}
        </div>
      );
    } else {
      // For all other dates, return the day number
      return (
        <div
          style={{
            color: "green",
          }}
        >
          {date.day}
        </div>
      );
    }
  }

  const handlePhotoClose = () => {
    setShowPhotoModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // console.log("Property:", property);
  // console.log("HousesDict:", props.housesDict);

  if (!property || !props.housesDict) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Styled.PropertyContainer>
        <NavBar />
        <ToastContainer />
        {property ? (
          <Title>{property.tagline}</Title>
        ) : (
          <Title>Loading...</Title>
        )}

        {/* <Title>{props.property.tagline}</Title> */}
        {/* <Title>{props.housesDict.tagline}</Title> */}

        <Styled.Gallery>
          <Styled.GalleryMainImage>
            <StyledImg
              src={props.housesDict.photos[0]}
              alt="main"
              onClick={handlePhotoOpen}
            ></StyledImg>
          </Styled.GalleryMainImage>
          <Styled.GallerySubImages>
            <StyledImg
              src={props.housesDict.photos[1]}
              alt="main"
              onClick={handlePhotoOpen}
            ></StyledImg>
            <StyledImg
              src={props.housesDict.photos[2]}
              alt="main"
              onClick={handlePhotoOpen}
            ></StyledImg>
            <StyledImg
              src={props.housesDict.photos[3]}
              alt="main"
              onClick={handlePhotoOpen}
            ></StyledImg>
            <StyledImg
              src={props.housesDict.photos[4]}
              alt="main"
              onClick={handlePhotoOpen}
            ></StyledImg>
          </Styled.GallerySubImages>
          <PhotoGalleryButton onClick={handlePhotoOpen}>
            <ButtonSpan>Show all photos</ButtonSpan>
          </PhotoGalleryButton>
        </Styled.Gallery>
        <Styled.PropertyBody>
          <Styled.PropertyBodyInfoContainer>
            {property ? (
              <div>
                <Title style={{ marginBottom: "0px" }}>
                  {property.tagline}
                </Title>
                <SubTitle style={{ marginTop: "0px", fontSize: "1rem" }}>
                  Property ID: {property.propertyId}
                </SubTitle>
              </div>
            ) : (
              <Title>Loading...</Title>
            )}
            {property && (
              <SubTitle>
                {property.numAllowedAdults} Guests {<GoDotFill size={"1rem"} />}{" "}
                {property.numBedrooms} Bedrooms {<GoDotFill size={"1rem"} />}{" "}
                {property.numBedrooms} Beds {<GoDotFill size={"1rem"} />}{" "}
                {property.numBathrooms} Baths
              </SubTitle>
            )}
            {property && <BodyText>{property.description}</BodyText>}

            {/* <BodyText>{props.housesDict.description}</BodyText> */}

            <ModalBackdrop
              onClick={handlePhotoClose}
              style={{
                opacity: showPhotoModal ? 1 : 0,
                visibility: showPhotoModal ? "visible" : "hidden",
              }}
            >
              <FadeInSection>
                <ModalContent
                  onClick={(e) => e.stopPropagation()}
                  style={{ opacity: showPhotoModal ? 1 : 0 }}
                  width={"95vw"}
                  height="95vh"
                >
                  <ModalPhotoContent>
                    <PhotoGroup
                      section_name="Property"
                      photo_content={
                        <ModalPhotoGrid>
                          {props.housesDict.photos.map((photo: string) => (
                            <ModalPhoto src={photo} alt="main"></ModalPhoto>
                          ))}{" "}
                          {/* <ModalPhoto
                          src={props.housesDict.photos[0]}
                          alt="main"
                        ></ModalPhoto>
                        <ModalPhoto
                          src={props.housesDict.photos[1]}
                          alt="main"
                        ></ModalPhoto>
                        <ModalPhoto
                          src={props.housesDict.photos[2]}
                          alt="main"
                        ></ModalPhoto>
                        <ModalPhoto
                          src={props.housesDict.photos[3]}
                          alt="main"
                        ></ModalPhoto>
                        <ModalPhoto
                          src={props.housesDict.photos[4]}
                          alt="main"
                        ></ModalPhoto> */}
                        </ModalPhotoGrid>
                      }
                    />
                  </ModalPhotoContent>
                </ModalContent>
              </FadeInSection>
            </ModalBackdrop>

            <ModalBackdrop
              onClick={handleClose}
              style={{
                opacity: showModal ? 1 : 0,
                visibility: showModal ? "visible" : "hidden",
              }}
            >
              <FadeInSection>
                <ModalContent
                  onClick={(e) => e.stopPropagation()}
                  style={{ opacity: showModal ? 1 : 0 }}
                >
                  {props.housesDict.amenities.essentials && (
                    <Styled.LinedDiv>
                      <Title>What this destination offers:</Title>
                      <Styled.StyledGrid columns="1fr 1fr 1fr 1fr 1fr">
                        {props.housesDict.amenities.essentials.map(
                          (amenity: string, index: number) => (
                            <BodyText key={index}>{amenity}</BodyText> // Corrected spelling
                          )
                        )}
                      </Styled.StyledGrid>
                    </Styled.LinedDiv>
                  )}
                  {props.housesDict.amenities.kitchen && (
                    <Styled.LinedDiv>
                      <Title>Kitchen amenities:</Title>
                      <Styled.StyledGrid columns="1fr 1fr 1fr 1fr 1fr">
                        {props.housesDict.amenities.kitchen.map(
                          (amenity: string, index: number) => (
                            <BodyText key={index}>{amenity}</BodyText> // Corrected spelling
                          )
                        )}
                      </Styled.StyledGrid>
                    </Styled.LinedDiv>
                  )}
                  {props.housesDict.amenities.entertainment && (
                    <Styled.LinedDiv>
                      <Title>Entertainment:</Title>
                      <Styled.StyledGrid columns="1fr 1fr 1fr 1fr 1fr">
                        {props.housesDict.amenities.entertainment.map(
                          (amenity: string, index: number) => (
                            <BodyText key={index}>{amenity}</BodyText> // Corrected spelling
                          )
                        )}
                      </Styled.StyledGrid>
                    </Styled.LinedDiv>
                  )}
                </ModalContent>
              </FadeInSection>
            </ModalBackdrop>

            <Styled.LinedDiv>
              <Title>House policies</Title>

              <Styled.StyledGrid columns="1fr 1fr">
                <Title fontSize="1.25rem">
                  <RiLoginBoxLine /> Check in after 4:00pm
                </Title>
                <Title fontSize="1.25rem">
                  <RiLogoutBoxLine /> Check out before 11:00am
                </Title>

                <Title fontSize="1.25rem">
                  <MdOutlinePets /> Pets{" "}
                  <BodyText color="grey">Max. 2 well-behaved dogs</BodyText>
                </Title>
                <Title fontSize="1.25rem">
                  <TbSmokingNo /> No Smoking
                </Title>
                <Title margin="0 0 10px" fontSize="1.25rem">
                  <ImCancelCircle /> No parties or events
                </Title>
              </Styled.StyledGrid>
            </Styled.LinedDiv>

            {props.housesDict.amenities.essentials && (
              <Styled.LinedDiv>
                <Title>What this destination offers:</Title>
                <Styled.StyledGrid columns="1fr 1fr 1fr 1fr 1fr">
                  {props.housesDict.amenities.essentials
                    .slice(0, 10)
                    .map((amenity: string, index: number) => (
                      <BodyText key={index}>{amenity}</BodyText>
                    ))}
                </Styled.StyledGrid>
                {props.housesDict.amenities.essentials.length > 10 && (
                  <SlidingButton
                    onClick={() => setIsModalOpen(true)}
                    style={{ verticalAlign: "middle" }}
                  >
                    <span>See all amenities</span>
                  </SlidingButton>
                )}
              </Styled.LinedDiv>
            )}

            {props.housesDict.amenities.kitchen && (
              <Styled.LinedDiv>
                <Title>Kitchen Amenities:</Title>
                <Styled.StyledGrid columns="1fr 1fr 1fr 1fr 1fr">
                  {props.housesDict.amenities.kitchen
                    .slice(0, 10)
                    .map((amenity: string, index: number) => (
                      <BodyText key={index}>{amenity}</BodyText>
                    ))}
                </Styled.StyledGrid>
                {props.housesDict.amenities.kitchen.length > 10 && (
                  <SlidingButton
                    onClick={() => setIsModalOpen(true)}
                    style={{ verticalAlign: "middle" }}
                  >
                    <span>See all amenities</span>
                  </SlidingButton>
                )}
              </Styled.LinedDiv>
            )}

            {props.housesDict.amenities.entertainment && (
              <Styled.LinedDiv>
                <Title>Entertainment:</Title>
                <Styled.StyledGrid columns="1fr 1fr 1fr 1fr 1fr">
                  {props.housesDict.amenities.entertainment
                    .slice(0, 10)
                    .map((amenity: string, index: number) => (
                      <BodyText key={index}>{amenity}</BodyText>
                    ))}
                </Styled.StyledGrid>
                {props.housesDict.amenities.entertainment.length > 10 && (
                  <SlidingButton
                    onClick={() => setIsModalOpen(true)}
                    style={{ verticalAlign: "middle" }}
                  >
                    <span>See all amenities</span>
                  </SlidingButton>
                )}
              </Styled.LinedDiv>
            )}
            {/* <CalendarWithPrices prices={{ "2024-01-26": 200 }} /> */}
            {/* Take out for now */}

            {/* {props.housesDict.priceScheme && (
            <Styled.LinedDiv>
              <Title>Rates</Title>
              <Styled.StyledTable>
                <thead>
                  <tr>
                    <th>Rate Period</th>
                    <th>Nightly</th>
                    <th>Fri/Sat</th>
                    <th>Weekly</th>
                    <th>Min Stay</th>
                  </tr>
                </thead>
                <tbody>
                  {showAllPayPeriods
                    ? props.housesDict.priceScheme.map((scheme: any, index: number) => (
                        <tr key={index}>
                          <td>{scheme.period}</td>
                          <td>{scheme.nightly}</td>
                          <td>{scheme.weekend_night}</td>
                          <td>{scheme.weekly}</td>
                          <td>{scheme.min_stay}</td>
                        </tr>
                      ))
                    : props.housesDict.priceScheme.map(
                        (scheme: any, index: number) =>
                          index < 3 && (
                            <tr key={index}>
                              <td>{scheme.period}</td>
                              <td>{scheme.nightly}</td>
                              <td>{scheme.weekend_night}</td>
                              <td>{scheme.weekly}</td>
                              <td>{scheme.min_stay}</td>
                            </tr>
                          )
                      )}
                </tbody>
              </Styled.StyledTable>
              <Styled.ViewAllButton
                onClick={() => setShowAllPayPeriods(!showAllPayPeriods)}
              >
                {showAllPayPeriods ? "Show less" : "View all pay periods"}
              </Styled.ViewAllButton>
            </Styled.LinedDiv>
          )} */}

            {/* Comment out availability calendar */}
            {/* <Styled.LinedDiv>
            <Title>Reference Calendar</Title>
            <Calendar
              inline
              numberOfMonths={windowWidth > 768 ? 2 : 1}
              className="multi-month-calendar"
              dateTemplate={dateTemplate}
            />
          </Styled.LinedDiv> */}
          </Styled.PropertyBodyInfoContainer>
          <Styled.PropertyBodyBookingContainer>
            <ContactCard
              props={{ props: props.housesDict, property: property }}
            />
          </Styled.PropertyBodyBookingContainer>
        </Styled.PropertyBody>
      </Styled.PropertyContainer>
      <BookNowSection
        style={{ borderTop: "1px solid #ccc" }}
        isExtended={isExtended}
      >
        <SubmitButton
          style={{ width: "96%", marginBottom: "20px" }}
          onClick={toggleExtension}
        >
          Contact Owner
        </SubmitButton>
        {isExtended && (
          <ContactCard
            props={{ props: props.housesDict, property: property }}
          />
        )}
      </BookNowSection>
    </div>
  );
};

export default Property;
