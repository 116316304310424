import styled from "styled-components";
import * as StyledTexts from "../styledTexts";
import ContactCard from "../components/ContactCard/ContactCard";
import NavBar from "../components/Navbar";
import { Row, Column } from "../styledComponents";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactPageContainer = styled.div`
  padding-top: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;
  min-height: 80vh;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const LinedDiv = styled.div`
  position: relative;
  /* Other styles for your div */
  //   &::after
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 2px; /* Thickness of the line */
    background: #e1e1e1; /* Color of the line */
    margin-top: -10px;
  }

  &::before {
    top: 0; /* Position at the top */
  }

  &::after {
    bottom: 0; /* Position at the bottom */
  }
`;

function ContactPage() {
  return (
    <>
      <NavBar />
      <ContactPageContainer>
        <ToastContainer />

        <StyledTexts.Title>Contact us:</StyledTexts.Title>
        <LinedDiv>
          <Row>
            <Column align="flex-start">
              <StyledTexts.Title>
                Property Manager: Yiping Ding
              </StyledTexts.Title>
              <StyledTexts.SubTitle>
                Phone: 1 (617) 416-8883{" "}
              </StyledTexts.SubTitle>
              <StyledTexts.SubTitle>
                Email: capeoceanvista@gmail.com{" "}
              </StyledTexts.SubTitle>
            </Column>
            <Column>
              <ContactCard
                props={{
                  props: { propertyId: null },
                  property: { bookings: [] },
                }}
              />
            </Column>
          </Row>
        </LinedDiv>
      </ContactPageContainer>
    </>
  );
}

export default ContactPage;
