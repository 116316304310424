import * as Styled from "./styles";
import { SubTitle, Title } from "../../styledTexts";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS for react-datepicker
import { SubmitButton } from "../../styledComponents";
import { sendEmail } from "../../api/email";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";

import { Calendar } from "primereact/calendar";
import { createGlobalStyle } from "styled-components";
import { NotifyAPI, EmailAPI, CalendarAPI } from "../../api";

const GlobalStyles = createGlobalStyle`
  .p-calendar .p-inputtext {
    border: 1px solid #808080; /* Custom border color */
    background-color: ${({ theme }) =>
      theme.backgroundColor}; /* Custom background color */ 
    color: ${({ theme }) => theme.textColors.primary}; /* Custom text color */
    font-family: ${({ theme }) => theme.fonts.primary}; /* Custom font family */
    width: 100%; /* Set width of the input */
    padding: 0.75em;
    margin: 0.75em;
    border: 1px solid #808080; /* Custom border color */

  font-size: 15px; }

  .p-calendar .p-datepicker {
    background-color: #e0f2f1; /* Custom background color for the popup */
    width: 800px; /* Set width of the popup */
    /* Other styles... */
  }

  /* Add more custom styles as needed */
  .multi-month-calendar .p-monthpicker {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .multi-month-calendar .p-monthpicker .p-monthpicker-month {
    flex: 0 0 45%; /* Adjust the percentage as needed to fit two months per row */
    margin: 0.5rem; /* Optional: Add some spacing between the months */
  }

  .p-calendar .p-datepicker table {
    font-size: 0.85em; /* Smaller text */
  }

  .p-calendar .p-monthpicker .p-monthpicker-month {
    flex: 0 0 45%; /* Adjust for better fitting */
    margin: 0.25rem; /* Smaller margins */
  }

  /* Adjust navigation buttons if needed */
  .p-calendar .p-datepicker .p-datepicker-header {
    /* Styles for header */
  }

  .p-calendar .p-datepicker .p-datepicker-prev,
  .p-calendar .p-datepicker .p-datepicker-next {
    /* Styles for prev and next buttons */
  }

  .Toastify__toast-container {
    /* Example styles */
    width: 500px;
    height: 300px;
  }
  
`;

const dayToDayOfWeek = (day: number) => {
  switch (day) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    default:
      return "Saturday";
  }
};

const monthToMonth = (month: number) => {
  switch (month) {
    case 0:
      return "January";
    case 1:
      return "Febuary";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "Septemper";
    case 9:
      return "October";
    case 10:
      return "November";
    default:
      return "December";
  }
};
interface booking {
  startDate: Date;
  endDate: Date;
  guestName?: string;
}
interface DateInfo {
  year: number;
  dayOfWeek: string;
  month: string;
  day: number;
}

interface FormValues {
  propertyId: number;
  firstName: string;
  lastName: string;
  guestEmail: string;
  phone: string;
  startDateInfo?: DateInfo;
  endDateInfo?: DateInfo;
  startDate?: Date | null;
  endDate?: Date | null;
  adults?: number;
  children?: number;
  pets?: boolean;
  message: string;
}
interface dateTemplateProps {
  day: number;
  month: number;
  year: number;
  otherMonth?: boolean;
  today?: boolean;
  selectable?: boolean;
}

interface PropertyProps {
  [key: string]: any;
}

type Booking = {
  startDate: Date;
  endDate: Date;
};

interface ErrorValues {
  firstName?: string;
  lastName?: string;
  startDate?: string;
  endDate?: string;
  guestEmail?: string;
  phone?: string;
  adults?: string;
  children?: string;
}

function round(num: number, decimalPlaces: number = 0) {
  const factor = 10 ** decimalPlaces;
  return Math.round((num + Number.EPSILON) * factor) / factor;
}

const CheckoutRow = (
  label: string,
  cost: number,
  fontSize: string = "18px"
) => {
  return (
    <Styled.Row
      style={{
        paddingLeft: "0.75rem",
        justifyContent: "space-between",
        paddingRight: "0.75rem",
        margin: "0rem",
      }}
    >
      <Title
        style={{
          fontSize: fontSize,
          fontWeight: "normal",
          margin: "0rem",
        }}
      >
        {label}
      </Title>
      <Title
        style={{ fontSize: fontSize, fontWeight: "normal", margin: "0rem" }}
      >
        ${round(cost)}
      </Title>
    </Styled.Row>
  );
};

const ContactCard: React.FC<PropertyProps> = (props) => {
  const [priceRanges, setPriceRanges] = useState<PriceRange[]>([]); // Corrected to an array of PriceRange objects
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [costPerNight, setCostPerNight] = useState(0); // Add this state variable
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null); // Start with null
  const [endDate, setEndDate] = useState<Date | null>(null); // Start with null
  const [formErrors, setFormErrors] = useState({} as ErrorValues); // Start with empty object
  const [numNights, setNumNights] = useState(0); // Add this state variable
  const [formValues, setFormValues] = useState<FormValues>(
    props.props.props.propertyId
      ? {
          propertyId: props.props.props.propertyId,
          firstName: "",
          lastName: "",
          guestEmail: "",
          phone: "",
          startDateInfo: {
            year: 0,
            dayOfWeek: "",
            day: 0,
            month: "",
          },
          endDateInfo: {
            year: 0,
            dayOfWeek: "",
            day: 0,
            month: "",
          },
          startDate: null,
          endDate: null,
          adults: 0,
          children: 0,
          pets: false,
          message: "",
        }
      : {
          propertyId: 0,
          firstName: "",
          lastName: "",
          guestEmail: "",
          phone: "",
          startDateInfo: {
            year: 0,
            dayOfWeek: "",
            day: 0,
            month: "",
          },
          endDateInfo: {
            year: 0,
            dayOfWeek: "",
            day: 0,
            month: "",
          },
          startDate: null,
          endDate: null,
          adults: 0,
          children: 0,
          pets: false,
          message: "",
        }
  );

  const getPriceRanges = async () => {
    if (!props.props.props.propertyId) return;
    if (!startDate || !endDate) return;
    if (startDate > endDate) return;

    // Ensure startDate and endDate are Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start > end) return;

    // Clone the dates to avoid modifying the original date objects
    const startOfDayDate: Date = new Date(start.setHours(0, 0, 0, 0));
    const endOfDayDate: Date = new Date(end.setHours(0, 0, 0, 0));

    if (startOfDayDate > endOfDayDate) return; // Check again after adjusting

    // Function to format date to YYYY-MM-DD
    const formatDate = (date: Date): string => {
      const year: number = date.getFullYear();
      const month: string = (date.getMonth() + 1).toString().padStart(2, "0");
      const day: string = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const formattedStartDate: string = formatDate(startOfDayDate);
    const formattedEndDate: string = formatDate(endOfDayDate);

    const propertyId: string = props.props.props.propertyId.toString(); // Adjust based on actual propertyId type

    const priceRanges = await CalendarAPI.getPriceRanges(
      propertyId,
      formattedStartDate,
      formattedEndDate
    );

    if (priceRanges.data.message === "No events found!") {
      setPriceRanges([]);
    }

    setPriceRanges(priceRanges.data.priceRanges);
  };

  useEffect(() => {
    getPriceRanges();
  }, [startDate, endDate]);

  // Correct the typos in your monthToMonth and dayToDayOfWeek functions
  // Ensure all your other functions are correct and properly typed as well

  interface PriceRange {
    start: string;
    end: string;
    summary: string;
  }

  // Adjust the useState initialization of priceRanges if necessary
  // const [priceRanges, setPriceRanges] = useState<PriceRange[]>([]);

  function parseDate(dateString: string): Date {
    const [year, month, day] = dateString
      .split("-")
      .map((num) => parseInt(num, 10));
    // JavaScript months are 0-indexed, so subtract 1 from the month
    return new Date(year, month - 1, day);
  }

  function iterateDatesAndSumPrices(): number {
    if (!startDate || !endDate || new Date(startDate) > new Date(endDate))
      return 0;

    const bookingStart: string = startDate.toISOString().substring(0, 10);
    const bookingEnd: string = endDate.toISOString().substring(0, 10);

    if (priceRanges.length === 0) {
      return 0;
    }

    console.log(priceRanges);

    let sum = 0;
    let currentDate: Date = parseDate(bookingStart);
    const end: Date = parseDate(bookingEnd);

    try {
      while (currentDate < end) {
        let found = false;
        for (const range of priceRanges) {
          const rangeStart: Date = parseDate(range.start);
          const rangeEnd: Date = parseDate(range.end);
          const summaryAsNumber: number = parseInt(range.summary); // Convert summary to number
          if (isNaN(summaryAsNumber)) {
            return 0;
          }

          if (currentDate >= rangeStart && currentDate < rangeEnd) {
            sum += summaryAsNumber;
            found = true;
          }
        }
        if (!found) {
          return 0;
        }
        found = false;

        // Move to the next day
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
      }
    } catch (error) {
      return 0;
    }

    return sum;
  }

  // In your ContactCard component
  useEffect(() => {
    // Ensure startDate and endDate are set and valid
    if (!startDate || !endDate) return;
    if (!priceRanges || priceRanges.length === 0) {
      return;
    }

    const formattedStartDate: string = startDate.toISOString().substring(0, 10);
    const formattedEndDate: string = endDate.toISOString().substring(0, 10);

    const totalSum = iterateDatesAndSumPrices();

    setEstimatedPrice(totalSum); // Assuming you have a state variable for storing the estimated price

    // Calculate the number of nights between startDate and endDate
    const start: Date = new Date(formattedStartDate);
    const end: Date = new Date(formattedEndDate);
    const oneDayInMs: number = 24 * 60 * 60 * 1000; // Milliseconds in a day
    const differenceInMs: number = end.getTime() - start.getTime();
    const numberOfNights: number = differenceInMs / oneDayInMs;
    setNumNights(numberOfNights);

    // Calculate cost per night
    const calculatedCostPerNight: number = totalSum / numberOfNights;
    setCostPerNight(calculatedCostPerNight);
  }, [priceRanges, startDate, endDate]);

  // function round(num: number) {
  //   return Math.round((num + Number.EPSILON) * 100) / 100;
  // }

  const mergeDateRanges = (dateRanges: booking[]): booking[] => {
    if (!dateRanges.length) return [];

    // Convert startDate and endDate to Date objects if they are not already
    const rangesWithDateObjects = dateRanges.map((range) => ({
      ...range,
      startDate: new Date(range.startDate),
      endDate: new Date(range.endDate),
    }));

    // Then proceed with your sorting and merging logic
    const sortedRanges = rangesWithDateObjects.sort(
      (a, b) => a.startDate.getTime() - b.startDate.getTime()
    );

    const mergedRanges = [sortedRanges[0]];

    for (let i = 1; i < sortedRanges.length; i++) {
      const lastRange = mergedRanges[mergedRanges.length - 1];
      const currentRange = sortedRanges[i];

      if (currentRange.startDate <= lastRange.endDate) {
        lastRange.endDate = new Date(
          Math.max(lastRange.endDate.getTime(), currentRange.endDate.getTime())
        );
      } else {
        mergedRanges.push(currentRange);
      }
    }

    return mergedRanges;
  };

  const disabledDates = mergeDateRanges(props.props.property.bookings).flatMap(
    (booking: Booking) => {
      const dates = [];
      // Start from the day after the start date
      let currentDate = new Date(booking.startDate);
      currentDate.setDate(currentDate.getDate() + 1);

      // End the day before the end date
      const endDate = new Date(booking.endDate);
      endDate.setDate(endDate.getDate() - 1);

      while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    }
  );

  const resetFormValues = () => {
    setStartDate(null);
    setEndDate(null);
    props.props.propertyId
      ? setFormValues({
          propertyId: props.props.props.propertyId || 0,
          firstName: "",
          lastName: "",
          guestEmail: "",
          phone: "",
          startDateInfo: {
            year: 0,
            dayOfWeek: "",
            month: "",
            day: 0,
          },
          endDateInfo: {
            year: 0,
            dayOfWeek: "",
            month: "",
            day: 0,
          },
          startDate: null,
          endDate: null,
          adults: 0,
          children: 0,
          pets: false,
          message: "",
        })
      : setFormValues({
          propertyId: 0,
          firstName: "",
          lastName: "",
          guestEmail: "",
          phone: "",
          message: "",
        });
  };

  const notify = (status: number, message: string) => {
    if (status === 200) {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onCheckBoxChange = () => {
    setFormValues({ ...formValues, ["pets"]: formValues.pets ? false : true });
  };

  const handleStartDateChange = (date: Date | Date[] | null) => {
    // Ensure the date is a single Date object or null, not undefined or an array

    const newDate = Array.isArray(date) ? date[0] : date;
    setStartDate(newDate);
    if (newDate) {
      setFormValues((prevValues) => ({
        ...prevValues,
        startDate: newDate,
        startDateInfo: {
          year: newDate.getFullYear(),
          dayOfWeek: dayToDayOfWeek(newDate.getDay()),
          month: monthToMonth(newDate.getMonth()),
          day: newDate.getDate(),
        },
      }));
    } else {
      // Handle the null case if necessary
    }
  };

  const handleEndDateChange = (date: Date | Date[] | null) => {
    const newDate = Array.isArray(date) ? date[0] : date;
    setEndDate(newDate);
    if (newDate) {
      setFormValues((prevValues) => ({
        ...prevValues,
        endDate: newDate,
        endDateInfo: {
          year: newDate.getFullYear(),
          dayOfWeek: dayToDayOfWeek(newDate.getDay()),
          month: monthToMonth(newDate.getMonth()),
          day: newDate.getDate(),
        },
      }));
    } else {
      // Handle the null case if necessary
    }
  };

  const onFormChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validateForm = (values: FormValues) => {
    const errors: ErrorValues = {};

    if (!values.guestEmail || values.guestEmail.trim() === "") {
      errors.guestEmail = "Email is required";
    } else if (!emailRegex.test(values.guestEmail)) {
      errors.guestEmail = "Email address is invalid";
    }

    if (!values.firstName || values.firstName.trim() === "") {
      errors.firstName = "First name is required";
    }
    if (!values.lastName || values.lastName.trim() === "") {
      errors.lastName = "Last name is required";
    }

    if (values.startDate && values.endDate) {
      const start = values.startDate;
      const end = values.endDate;

      // Check if start date is same as or after end date
      if (
        start.getFullYear() === end.getFullYear() &&
        start.getMonth() === end.getMonth() &&
        start.getDate() === end.getDate()
      ) {
        errors.endDate = "End date cannot be the same as start date";
      } else if (start > end) {
        errors.endDate = "End date must be after start date";
      }

      const isOverlap = props.props.property.bookings.some(
        (booking: Booking) => {
          const bookingStart = new Date(booking.startDate);
          const bookingEnd = new Date(booking.endDate);

          return start < bookingEnd && end > bookingStart;
        }
      );

      if (isOverlap) {
        errors.startDate = "Selected dates overlap with existing bookings";
        errors.endDate = "Selected dates overlap with existing bookings";
      }
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the current date time to midnight for comparison

    if (values.startDate) {
      const start = values.startDate;
      if (start <= today) {
        errors.startDate = "Start date must be a day in the future";
      }
    }

    if (values.endDate) {
      const end = values.endDate;
      if (end <= today) {
        errors.endDate = "End date must be a day in the future";
      }
    }
    // if (!values.children) {
    //   errors["children"] = "Number of children is required";
    // }
    setFormErrors(errors);
    return errors;
  };

  const sendSMSMessage = async (formValues: FormValues) => {
    const message = `New booking request from Cape Ocean Vista: 

${formValues.firstName} ${formValues.lastName} is interested in ${
      props.props.propertyId
        ? `booking ${props.props.property.address}`
        : "a general inquiry."
    }
      
Property ID: ${formValues.propertyId ? formValues.propertyId : 0}
Check-in Date: ${
      formValues.startDateInfo && formValues.startDateInfo.year !== 0
        ? `${formValues.startDateInfo.dayOfWeek} ${formValues.startDateInfo.month} ${formValues.startDateInfo.day}, ${formValues.startDateInfo.year}`
        : "Not provided."
    }
Check-out Date: ${
      formValues.endDateInfo && formValues.endDateInfo.year !== 0
        ? `${formValues.endDateInfo.dayOfWeek} ${formValues.endDateInfo.month} ${formValues.endDateInfo.day}, ${formValues.endDateInfo.year}`
        : "Not provided."
    }

Guest list:
Number of adults: ${
      formValues.adults !== 0 ? `${formValues.adults} adults` : "Not provided."
    }
Number of children: ${
      formValues.children
        ? `${formValues.children} ${
            formValues.children === 1 ? "child" : "children"
          }`
        : "Not provided."
    }
Pets? ${formValues.pets ? "Yes" : "No"}

Contact information: 
Email: ${formValues.guestEmail}
${formValues.phone ? `Phone: ${formValues.phone}` : ""}.
${
  formValues.message
    ? `Here is a copy of your message: ${formValues.message}`
    : ""
}`;
    // Send SMS notification
    const smsResponse = await NotifyAPI.sendSMS({
      message: message,
    });

    return smsResponse;
  };

  const getInquiryMessage = (formValues: FormValues) => {
    const InquiryDetails = `<strong>Length of stay:</strong><br/>
    Check-in Date: ${
      formValues.startDateInfo && formValues.startDateInfo.year !== 0
        ? `<strong>
          ${formValues.startDateInfo.dayOfWeek} ${formValues.startDateInfo.month} ${formValues.startDateInfo.day}, ${formValues.startDateInfo.year}
        </strong>`
        : "Not provided."
    }<br/>
    Check-out Date: ${
      formValues.endDateInfo && formValues.endDateInfo.year !== 0
        ? `<strong>
          ${formValues.endDateInfo.dayOfWeek} ${formValues.endDateInfo.month} ${formValues.endDateInfo.day}, ${formValues.endDateInfo.year}
        </strong>`
        : "Not provided."
    }
    <br/>
    <br/>
    <strong>Guest list:</strong><br>
    Number of adults: ${
      formValues.adults !== 0 ? `${formValues.adults} adults` : "Not provided."
    }
    <br/>
    Number of children: ${
      formValues.children
        ? `<strong>${formValues.children} ${
            formValues.children === 1 ? "child" : "children"
          }</strong>`
        : "Not provided."
    }<br/>
    Pets? ${formValues.pets ? "Yes" : "No"}
    
    <p><strong>Contact information:</strong>
    <br/>Email: <a href="mailto:${formValues.guestEmail}">${
      formValues.guestEmail
    }</a>  
    ${formValues.phone ? `<br/>Phone: ${formValues.phone}` : ""}.</p>
    ${
      formValues.message
        ? `Here is a copy of your message: ${formValues.message}`
        : ""
    }`;
    return InquiryDetails;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmitting(true);

    const errors = validateForm(formValues);

    if (Object.keys(errors).length === 0) {
      try {
        const inquiryMessage = getInquiryMessage(formValues);
        const emailResponse = sendEmail({
          ...formValues,
          inquiryMessage: inquiryMessage,
          quotedPrice: estimatedPrice,
        });
        const confirmationEmailResponse = await EmailAPI.sendConfirmationEmail({
          ...formValues,
          inquiryMessage,
        });
        const smsResponse = await sendSMSMessage(formValues);

        var successMessage = "";
        var errorMessage = "";

        if (formValues.propertyId === 0) {
          successMessage = `Thank you for your contacting us! You will a confirmation email from us and recieve a response shortly.`;
          errorMessage = `Sorry, there was an error and your message was not recieved. Please try again later or reachout directly to property owner.`;
        } else {
          successMessage = `Thank you for your booking request! You will recieve a confirmation email and be contacted shortly by the property owner.`;
          errorMessage = `Sorry, there was an error and your booking request was not recieved. Please try again later or reachout directly to property owner.`;
        }

        if (emailResponse.status !== 200 && smsResponse.status !== 200) {
          notify(500, errorMessage);
        } else if (
          (emailResponse.status === 200 || smsResponse.status === 200) &&
          confirmationEmailResponse.status === 200
        ) {
          notify(200, successMessage);
        } else {
          notify(500, errorMessage);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  function isSameDay(date1: Date, date2: Date) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  function dateTemplate(date: dateTemplateProps) {
    const checkDate = new Date(date.year, date.month, date.day);
    checkDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const mergedBookings = mergeDateRanges(props.props.property.bookings);

    const isBooked = mergedBookings.some((booking: Booking) => {
      const startDate = new Date(booking.startDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(booking.endDate);
      endDate.setHours(0, 0, 0, 0);

      return checkDate > startDate && checkDate < endDate;
    });

    const isStartBooking = mergedBookings.some((booking: Booking) => {
      const startDate = new Date(booking.startDate);
      startDate.setHours(0, 0, 0, 0);
      return isSameDay(checkDate, startDate);
    });

    const isEndBooking = mergedBookings.some((booking: Booking) => {
      const endDate = new Date(booking.endDate);
      endDate.setHours(0, 0, 0, 0);
      return isSameDay(checkDate, endDate);
    });

    if (checkDate < today) {
      return (
        <div
          style={{
            textDecoration: "line-through",
            color: "grey", // Example: red color for the crossed-out dates
          }}
        >
          {date.day}
        </div>
      );
    }

    if (isStartBooking) {
      return (
        <div style={{ color: "orange", textDecoration: "underline" }}>
          {date.day}
        </div>
      );
    } else if (isEndBooking) {
      return (
        <div style={{ color: "orange", textDecoration: "overline" }}>
          {date.day}
        </div>
      );
    } else if (isBooked) {
      return (
        <div
          style={{
            color: "red",
            textDecoration: "line-through",
            cursor: "not-allowed",
          }}
        >
          {date.day}
        </div>
      );
    } else {
      // Default style for non-booked dates
      return <div style={{ color: "green" }}>{date.day}</div>;
    }
  }

  return (
    <Styled.PropertyBodyBookingForm onSubmit={handleSubmit}>
      <GlobalStyles />
      <Title style={{ marginBottom: "0" }}>Contact Owner</Title>

      <Styled.Row>
        <Styled.StyledInput
          name="firstName"
          placeholder="First Name*"
          value={formValues.firstName}
          onChange={onFormChange}
        />
        <Styled.StyledInput
          name="lastName"
          placeholder="Last Name*"
          value={formValues.lastName}
          onChange={onFormChange}
        />
      </Styled.Row>
      <Styled.Row>
        <Styled.ErrorMessage>{formErrors.firstName}</Styled.ErrorMessage>
        <Styled.ErrorMessage>{formErrors.lastName}</Styled.ErrorMessage>
      </Styled.Row>
      <Styled.Row>
        <Styled.StyledInput
          name="guestEmail"
          placeholder="Email*"
          value={formValues.guestEmail}
          onChange={onFormChange}
        />
      </Styled.Row>
      <Styled.Row>
        <Styled.ErrorMessage>{formErrors.guestEmail}</Styled.ErrorMessage>
      </Styled.Row>
      <Styled.Row>
        <Styled.StyledInput
          name="phone"
          placeholder="Phone"
          value={formValues.phone}
          onChange={onFormChange}
        />
      </Styled.Row>
      <Styled.Row>
        <Styled.ErrorMessage>{formErrors.phone}</Styled.ErrorMessage>
      </Styled.Row>

      <div>
        <Styled.Row>
          <Calendar
            name="startDate"
            value={startDate}
            onChange={(e) => handleStartDateChange(e.value ? e.value : null)}
            placeholder="Check-In Date"
            dateFormat="mm/dd/yy"
            disabledDates={disabledDates}
            minDate={new Date()}
            dateTemplate={dateTemplate}
            // Other optional properties...
          />

          {/* <Styled.StyledDatePicker
              selected={startDate}
              name="startDate"
              // onChange={(date: Date | null) => setStartDate(date)}
              onChange={handleStartDateChange}
              placeholderText="Check-In Date"
              autoComplete="off"
            /> */}

          <Calendar
            name="endDate"
            value={endDate}
            onChange={(e) => handleEndDateChange(e.value ? e.value : null)}
            placeholder="Checkout Date"
            dateFormat="mm/dd/yy"
            minDate={new Date()}
            disabledDates={disabledDates}
            dateTemplate={dateTemplate}
          />
          {/* <Styled.StyledDatePicker
              selected={endDate}
              name="endDate"
              onChange={handleEndDateChange}
              // onChange={(date: Date | null) => setEndDate(date)}
              placeholderText="Checkout Date"
              autoComplete="off"
            /> */}
        </Styled.Row>
        <Styled.Row>
          <Styled.ErrorMessage>{formErrors.startDate}</Styled.ErrorMessage>
          <Styled.ErrorMessage>{formErrors.endDate}</Styled.ErrorMessage>
        </Styled.Row>
      </div>

      <div>
        <Styled.Row>
          <Styled.StyledSelect
            name="adults"
            defaultValue={0}
            onChange={onFormChange}
          >
            <option value={0} disabled hidden>
              Adults
            </option>

            {Array.from(
              { length: props.props.property.numAllowedAdults || 12 },
              (_, i) => i + 1
            ).map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </Styled.StyledSelect>
          <Styled.StyledSelect
            name="children"
            defaultValue={0}
            onChange={onFormChange}
          >
            <option value={0} disabled hidden>
              Children
            </option>

            {Array.from(
              { length: props.props.property.numAllowedAdults || 5 },
              (_, i) => i
            ).map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
            {/* Add more options as needed */}
          </Styled.StyledSelect>
        </Styled.Row>

        <Styled.Row>
          <Styled.CheckboxWrapper>
            <Styled.HiddenCheckbox id="check1" checked={formValues.pets} />
            <Styled.StyledLabel htmlFor="check1" onClick={onCheckBoxChange}>
              I am traveling with pets
            </Styled.StyledLabel>
          </Styled.CheckboxWrapper>
        </Styled.Row>
      </div>

      <Styled.Row>
        <Styled.StyledTextArea
          name="message"
          placeholder="Your Message ..."
          onChange={onFormChange}
        />
      </Styled.Row>
      {costPerNight > 0 && (
        <div style={{ paddingTop: "0rem" }}>
          {CheckoutRow(
            `$${round(costPerNight)} x ${numNights} nights`,
            round(estimatedPrice)
          )}
          {props.props.property.cleaningFee
            ? CheckoutRow("Cleaning Fee", props.props.property.cleaningFee)
            : ""}
          <hr
            style={{
              padding: "0",
              marginTop: "3px",
              marginLeft: "0.75rem",
              marginRight: "0.75rem",
            }}
          ></hr>
          <Styled.Row
            style={{
              paddingLeft: "0.75rem",
              justifyContent: "space-between",
              paddingRight: "0.75rem",
              fontSize: "18px",
            }}
          >
            Total Cost*:{" "}
            <Title
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                padding: "0",
                paddingLeft: "2px",
                margin: "0",
              }}
            >
              {" "}
              $
              {round(estimatedPrice + props.props.property.cleaningFee) ||
                round(estimatedPrice)}
            </Title>
          </Styled.Row>

          <Styled.Row
            style={{ paddingLeft: "0.75rem", paddingRight: "0.75rem" }}
          >
            <SubTitle
              style={{
                fontSize: "13px",
                paddingBottom: "0",
                marginBottom: "10px",
                marginTop: "5px",
              }}
            >
              *This is an estimate based on the season. For exact price, please
              contact owner.
            </SubTitle>
          </Styled.Row>
        </div>
      )}

      <Styled.Row>
        <SubmitButton
          style={{ marginTop: "0.5rem" }}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </SubmitButton>
      </Styled.Row>
    </Styled.PropertyBodyBookingForm>
  );
};

export default ContactCard;
