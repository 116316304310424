import React from "react";
import styled from "styled-components";
import * as Texts from "../styledTexts";
import { Link } from "react-router-dom";

// Styled component for the footer
const StyledFooter = styled.footer`
  
  background-color: #5e8b95;
  text-align: center;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
  

  a {
\    text-decoration: none;
  }
`;

// Footer component
const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <Texts.Title>
        <Link to="/contact">Contact Property Owner: Yiping Ding</Link>
      </Texts.Title>
    </StyledFooter>
  );
};

export default Footer;
