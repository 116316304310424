import axios from "axios";
import { Base } from "../config";

export const getPriceRanges = async (calendarName, startDate, endDate) => {
  const response = await axios.get(
    `${Base}/calendar/listEvents/${calendarName}/${startDate}/${endDate}`
  );
  if (response.status === 200) {
    return { data: response.data, status: 200, error: null };
  } else {
    return {
      data: response.data,
      status: 500,
      error: "Error fetching price ranges",
    };
  }
};
