import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Property from "../types/PropertyType";
import { PropertyAPI } from "../api";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import NavBar from "../components/Navbar";
import { useAuth } from "../contexts/AuthContext";
import * as Styled from "../ui/input";
import styled, { createGlobalStyle } from "styled-components";
import { Container, Row, Column } from "../ui/layout";
import * as StyledTexts from "../styledTexts";
import * as StyledComponents from "../styledComponents";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineArrowBack } from "react-icons/md";
import { SlidingButton } from "../styledComponents";

const ContentContainer = styled.div`
  // display: flex;
  margin-top: 20px;
  // margin-left: 20px;
`;

interface ButtonProps {
  color?: string;
  backgroundColor?: string;
}

export const BackSlidingButton = styled.button<ButtonProps>`
  display: inline-block;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.backgroundColors.primary};
  border: 1px solid ${({ theme }) => theme.backgroundColors.secondary};
  color: ${({ color, theme }) => color || theme.backgroundColors.secondary};
  text-align: center;
  font-size: 18px;
  padding: 5px;
  width: 200px;
  padding-left: 0px;

  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-left: 0px;

  span {
    color: ${({ theme }) => theme.textColors.primary};
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: all 0.5s;
    color: #244c54;
    font-weight: bold;
    padding-left: 0px; // Start with no extra padding

    &:before {
      content: "\\2190"; // Left-pointing arrow
      position: absolute;
      opacity: 0; // Start with the arrow invisible
      top: 0;
      left: -20px; // Start off to the left, outside of the button's view
      transition: 0.5s;
    }
  }

  &:hover span {
    padding-left: 25px; // Shift text to the right on hover
  }

  &:hover span:before {
    opacity: 1; // Make the arrow visible on hover
    left: 5px; // Move arrow into view on hover
  }
`;

// export const BackSlidingButton = styled(SlidingButton)<ButtonProps>`
//   display: inline-block;
//   border-radius: 4px;
//   font-family: ${({ theme }) => theme.fontFamily};
//   background-color: ${({ backgroundColor, theme }) =>
//     backgroundColor || theme.backgroundColors.primary};
//   border: 1px solid ${({ theme }) => theme.backgroundColors.secondary};
//   color: ${({ color, theme }) => color || theme.backgroundColors.secondary};
//   text-align: center;
//   font-size: 18px;
//   padding: 5px;
//   width: 200px;
//   padding-left: 0px;

//   transition: all 0.5s;
//   cursor: pointer;
//   margin: 5px;
//   margin-left: 0px;

//   span {
//     color: ${({ theme }) => theme.textColors.primary};
//     cursor: pointer;
//     display: inline-block;
//     position: relative;
//     transition: 0.5s;
//     color: #244c54;
//     font-weight: bold;

//     &:after {
//       content: "\\2190"; // Changed this to a left-pointing arrow
//       position: absolute;
//       opacity: 0;
//       top: 0;
//       right: -20px; // Adjust position if needed to better fit the arrow
//       transition: 0.5s;
//     }
//   }

//   &:hover span {
//     padding-right: 25px;
//   }

//   &:hover span:after {
//     opacity: 1;
//     right: 0; // Ensure the arrow moves or appears correctly on hover
//   }
// `;

const BookingPanel = styled.div`
  width: 100%;

  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;

  // box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.6);
`;

const GlobalStyles = createGlobalStyle`
  .p-calendar .p-inputtext {
    border: 1px solid #808080; /* Custom border color */
    background-color: ${({ theme }) =>
      theme.backgroundColor}; /* Custom background color */ 
    color: ${({ theme }) => theme.textColors.primary}; /* Custom text color */
    font-family: ${({ theme }) => theme.fonts.primary}; /* Custom font family */
    width: 100%; /* Set width of the input */
    padding: 0.75em;
    margin: 0.75em;
    border: 1px solid #808080; /* Custom border color */

  font-size: 15px; }

  .p-calendar .p-datepicker {
    background-color: #e0f2f1; /* Custom background color for the popup */
    width: 800px; /* Set width of the popup */
    /* Other styles... */
  }

  /* Add more custom styles as needed */
  .multi-month-calendar .p-monthpicker {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .multi-month-calendar .p-monthpicker .p-monthpicker-month {
    flex: 0 0 45%; /* Adjust the percentage as needed to fit two months per row */
    margin: 0.5rem; /* Optional: Add some spacing between the months */
  }

  .p-calendar .p-datepicker table {
    font-size: 0.85em; /* Smaller text */
  }

  .p-calendar .p-monthpicker .p-monthpicker-month {
    flex: 0 0 45%; /* Adjust for better fitting */
    margin: 0.25rem; /* Smaller margins */
  }

  /* Adjust navigation buttons if needed */
  .p-calendar .p-datepicker .p-datepicker-header {
    /* Styles for header */
  }

  .p-calendar .p-datepicker .p-datepicker-prev,
  .p-calendar .p-datepicker .p-datepicker-next {
    /* Styles for prev and next buttons */
  }
`;
interface formValues {
  address: string;
  tagline: string;
  description: string;
  amenities: string[];
  photos: string[];
  thumbnail: string;
  numAllowedAdults: number;
  numAllowedChildren: number;
  numBedrooms: number;
  numBathrooms: number;
  numBeds: number;
  owner: string;
  bookings: booking[];
  reviews: string[];
  basePrice: number;
  prices: number[];
  cleaningFee: number;
}

interface booking {
  startDate: Date;
  endDate: Date;
  guestName?: string;
}

interface Booking {
  startDate: Date;
  endDate: Date;
  guestName?: string;
}

interface errorValues {
  address?: string;
}

const validate = (values: Property) => {
  const errors: errorValues = {};

  if (!values.address) {
    errors.address = "Required";
  }
};

interface dateTemplateProps {
  day: number;
  month: number;
  year: number;
  otherMonth?: boolean;
  today?: boolean;
  selectable?: boolean;
}
interface ErrorProps {
  startDate?: string;
  endDate?: string;
  guestName?: string;
}

type MenuItem = {
  id: number;
  label: string;
  content: JSX.Element; // Content can be any JSX.Element
};

interface GroupedBookings {
  [monthYear: string]: Booking[];
}

function EditProperty() {
  const { isLogged } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const property = location.state.property;
  const { propertyId } = useParams();
  const [tempProperty, setTempProperty] = useState<Property | null>(null);

  const [dateRanges, setDateRanges] = useState<booking[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [guestName, setGuestName] = useState<string>("");
  const [errors, setErrors] = useState<ErrorProps>({});
  const [markedForDeletion, setMarkedForDeletion] = useState<number[]>([]);
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);

  const [formValues, setFormValues] = useState<formValues>({
    address: property.address,
    tagline: property.tagline,
    description: property.description,
    amenities: property.amenities,
    photos: property.photos,
    thumbnail: property.thumbnail,
    numAllowedAdults: property.numAllowedAdults,
    numAllowedChildren: property.numAllowedChildren,
    numBedrooms: property.numBedrooms,
    numBathrooms: property.numBathrooms,
    numBeds: property.numBeds,
    owner: property.owner,
    bookings: property.bookings,
    reviews: property.reviews,
    basePrice: property.basePrice,
    prices: property.prices,
    cleaningFee: property.cleaningFee,
  });

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log("etarget", e.target.name, e.target.value);
  //   setFormValues({ ...formValues, [e.target.name]: e.target.value });
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("etarget", e.target.name, e.target.value);
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getMenuItems = () => [
    {
      id: 1,
      label: "Property Details",
      content: <div>{/* Add other inputs as needed */}</div>,
    },
    {
      id: 2,
      label: "Booking Details",
      content: <div>Content of Item 2 goes here</div>,
    },
    // Add more items as needed
  ];

  const notify = () => {
    toast.success("Form submitted successfully!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleGuestNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    setGuestName(e.target.value);
  };

  const addDateRange = () => {
    if (validateDateRange()) {
      console.log("startDate", startDate, "endDate", endDate);
      if (startDate && endDate && startDate < endDate) {
        const newDateRange = {
          startDate: startDate,
          endDate: endDate,
          guestName: guestName,
        };
        setDateRanges([...dateRanges, newDateRange]);
        setGuestName("");
      }
    }
  };

  const removeDateRange = (indexToRemove: number) => {
    setDateRanges((currentDateRanges) =>
      currentDateRanges.filter((_, index) => index !== indexToRemove)
    );
  };

  const formik = useFormik({
    initialValues: {
      address: property.address,
      tagline: property.tagline,
      description: property.description,
      amenities: property.amenities,
      photos: property.photos,
      thumbnail: property.thumbnail,
      numAllowedAdults: property.numAllowedAdults,
      numAllowedChildren: property.numAllowedChildren,
      numBedrooms: property.numBedrooms,
      numBathrooms: property.numBathrooms,
      numBeds: property.numBeds,
      owner: property.owner,
      bookings: property.bookings,
      reviews: property.reviews,
      basePrice: property.basePrice,
      prices: property.prices,
      propertyId: property.propertyId,
      cleaningFee: property.cleaningFee,
    },
    validate,
    onSubmit: (values) => {
      console.log("values", values);
      const finalBookings = values.bookings
        ?.filter((_, index) => !markedForDeletion.includes(index))
        .concat(dateRanges);
      const updatedProperty = { ...values, bookings: finalBookings };

      alert(JSON.stringify(updatedProperty, null, 2));
      // values.bookings = values.bookings?.concat(dateRanges);
      PropertyAPI.updateProperty(property._id, { property: updatedProperty });
      notify();
    },
  });

  const menuItems = [
    {
      id: 1,
      label: "Content",
      content: <div></div>,
    },
    {
      id: 2,
      label: "Calendar",
      content: <div></div>,
    },
    // Add more items as needed
  ];

  const [selectedItem, setSelectedItem] = useState<MenuItem>(menuItems[0]);

  useEffect(() => {
    // Immediately check if the user is not logged in
    if (!isLogged) {
      navigate("/login");
      return; // Prevent further execution if the user isn't logged in
    }

    const fetchProperty = async () => {
      // If property is passed through location.state, use it
      if (location.state && location.state.property) {
        setTempProperty(location.state.property);
      } else {
        // Otherwise, attempt to fetch the property by its ID
        try {
          const fetchedProperty = await PropertyAPI.getProperty(propertyId);
          if (!fetchedProperty) {
            navigate("/owner-view"); // Redirect if the property is not found
          } else {
            setTempProperty(fetchedProperty);
          }
        } catch (error) {
          console.error("Failed to fetch property:", error);
          navigate("/owner-view"); // Redirect in case of an error
        }
      }
    };

    fetchProperty();
  }, [navigate, propertyId, location.state, isLogged]);

  useEffect(() => {
    const getDisabledDates = mergeDateRanges(
      property.bookings.concat(dateRanges)
    ).flatMap((booking: booking) => {
      const dates = [];
      // Start from the day after the start date
      let currentDate = new Date(booking.startDate);
      currentDate.setDate(currentDate.getDate() + 1);

      // End the day before the end date
      const endDate = new Date(booking.endDate);
      endDate.setDate(endDate.getDate() - 1);

      while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    });
    setDisabledDates(getDisabledDates);
  }, [dateRanges, property.bookings]);

  const markForDeletion = (index: number) => {
    setMarkedForDeletion((current) => [...current, index]);
  };

  const undoDeletion = (index: number) => {
    setMarkedForDeletion((current) => current.filter((i) => i !== index));
  };

  //   const [formValues, setFormValues] = useState<formValues>(property);

  // useEffect(() => {
  //   const mergedRanges = mergeDateRanges(dateRanges);
  //   if (JSON.stringify(mergedRanges) !== JSON.stringify(dateRanges)) {
  //     setDateRanges(mergedRanges);
  //   }
  // }, [dateRanges]);

  const handleStartDateChange = (date: Date | null) => {
    console.log(date);
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    console.log(date);
    setEndDate(date);
  };

  const mergeDateRanges = (dateRanges: booking[]): booking[] => {
    if (!dateRanges.length) return [];

    // Convert startDate and endDate to Date objects if they are not already
    const rangesWithDateObjects = dateRanges.map((range) => ({
      ...range,
      startDate: new Date(range.startDate),
      endDate: new Date(range.endDate),
    }));

    // Then proceed with your sorting and merging logic
    const sortedRanges = rangesWithDateObjects.sort(
      (a, b) => a.startDate.getTime() - b.startDate.getTime()
    );

    const mergedRanges = [sortedRanges[0]];

    for (let i = 1; i < sortedRanges.length; i++) {
      const lastRange = mergedRanges[mergedRanges.length - 1];
      const currentRange = sortedRanges[i];

      if (currentRange.startDate <= lastRange.endDate) {
        lastRange.endDate = new Date(
          Math.max(lastRange.endDate.getTime(), currentRange.endDate.getTime())
        );
      } else {
        mergedRanges.push(currentRange);
      }
    }

    return mergedRanges;
  };

  function dateTemplate(date: dateTemplateProps) {
    const checkDate = new Date(date.year, date.month, date.day);
    checkDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const mergedBookings = mergeDateRanges(
      property.bookings.concat(dateRanges)
    );

    const isBooked = mergedBookings.some((booking: booking) => {
      const startDate = new Date(booking.startDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(booking.endDate);
      endDate.setHours(0, 0, 0, 0);

      return checkDate > startDate && checkDate < endDate;
    });

    const isStartBooking = mergedBookings.some((booking: booking) => {
      const startDate = new Date(booking.startDate);
      startDate.setHours(0, 0, 0, 0);
      return isSameDay(checkDate, startDate);
    });

    const isEndBooking = mergedBookings.some((booking: booking) => {
      const endDate = new Date(booking.endDate);
      endDate.setHours(0, 0, 0, 0);
      return isSameDay(checkDate, endDate);
    });

    if (checkDate < today) {
      return (
        <div
          style={{
            textDecoration: "line-through",
            color: "grey", // Example: red color for the crossed-out dates
          }}
        >
          {date.day}
        </div>
      );
    }

    if (isStartBooking) {
      return (
        <div style={{ color: "orange", textDecoration: "underline" }}>
          {date.day}
        </div>
      );
    } else if (isEndBooking) {
      return (
        <div style={{ color: "orange", textDecoration: "overline" }}>
          {date.day}
        </div>
      );
    } else if (isBooked) {
      return (
        <div
          style={{
            color: "red",
            textDecoration: "line-through",
            cursor: "not-allowed",
          }}
        >
          {date.day}
        </div>
      );
    } else {
      // Default style for non-booked dates
      return <div style={{ color: "green" }}>{date.day}</div>;
    }
  }

  function isSameDay(date1: Date, date2: Date) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const validateDateRange = () => {
    let validationErrors: ErrorProps = {};

    if (!startDate) {
      validationErrors.startDate = "Start date is required";
    }

    if (!endDate) {
      validationErrors.endDate = "End date is required";
    }

    if (startDate && endDate) {
      if (startDate > endDate) {
        validationErrors.startDate = "Start date must be before end date";
      } else {
        // Check if the end date is at least one day after the start date
        const nextDay = new Date(startDate);
        nextDay.setDate(nextDay.getDate() + 1);

        if (endDate < nextDay) {
          validationErrors.endDate =
            "End date must be at least one day after the start date";
        }
      }
    }

    if (!guestName.trim()) {
      validationErrors.guestName = "Guest name is required";
    }

    setErrors(validationErrors); // Update the errors state
    return Object.keys(validationErrors).length === 0; // Return true if no errors
  };

  const removeBooking = (index: number) => {
    const updatedBookings = formik.values.bookings?.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("bookings", updatedBookings);
  };

  // Utility function to sort bookings by start date
  const sortBookingsByStartDate = (bookings: Booking[]): Booking[] => {
    return bookings.sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );
  };

  // Utility function to group bookings by month
  const groupBookingsByMonth = (sortedBookings: Booking[]): GroupedBookings => {
    return sortedBookings.reduce((acc: GroupedBookings, booking: Booking) => {
      const monthYear = new Date(booking.startDate).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(booking);
      return acc;
    }, {});
  };

  const renderSortedAndGroupedBookings = () => {
    // Ensure formik.values.bookings is treated as an empty array if it's undefined
    const bookings: Booking[] = formik.values.bookings ?? [];

    // Sort bookings by start date
    const sortedBookings = sortBookingsByStartDate(bookings);

    // Group sorted bookings by month
    const groupedBookings = groupBookingsByMonth(sortedBookings);

    return Object.entries(groupedBookings).map(
      ([monthYear, bookings], index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <h3>{monthYear}</h3>
          {bookings.map((booking, bookingIndex) => {
            // Find the original index of the booking in formik.values.bookings for accurate marking and undoing
            const originalIndex =
              formik.values.bookings?.findIndex((b) => b === booking) ?? -1;
            return (
              <div
                key={bookingIndex}
                style={{
                  textDecoration: markedForDeletion.includes(originalIndex)
                    ? "line-through"
                    : "none",
                }}
              >
                {new Date(booking.startDate).toLocaleDateString()} -{" "}
                {new Date(booking.endDate).toLocaleDateString()}{" "}
                {booking.guestName}
                {markedForDeletion.includes(originalIndex) ? (
                  <button
                    type="button"
                    onClick={() => undoDeletion(originalIndex)}
                  >
                    Undo
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => markForDeletion(originalIndex)}
                  >
                    Remove
                  </button>
                )}
              </div>
            );
          })}
        </div>
      )
    );
  };

  return (
    <div>
      {property ? (
        <div>
          <ToastContainer />
          <NavBar logoSrc="../../../horizontal_logo.jpg" />
          <Row>
            <BackSlidingButton
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            >
              <Link to={"/owner-view"}>
                <span>Back to Owner View</span>
              </Link>
            </BackSlidingButton>
          </Row>
          <form onSubmit={formik.handleSubmit}>
            <Row
              style={{
                marginTop: "20px",
                verticalAlign: "middle",
                paddingRight: "20px",
                paddingLeft: "10px",
              }}
            >
              <StyledTexts.Title
                style={{
                  marginTop: "0px",
                  marginLeft: "10px",
                  fontSize: "30px",
                }}
              >
                Edit Property
              </StyledTexts.Title>
              <StyledComponents.SubmitButton
                style={{ marginTop: "0px", width: "300px" }}
                type="submit"
              >
                Save Changes
              </StyledComponents.SubmitButton>
              {/* <StyledComponents.PrimaryButton>
                Submit
              </StyledComponents.PrimaryButton> */}
            </Row>

            <div
              style={{
                display: "flex",
                minHeight: "70vh",
                borderTop: "1px solid #ccc",
              }}
            >
              <div
                style={{
                  width: "300px",
                  overflowY: "auto",
                  borderRight: "1px solid #ccc",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {getMenuItems().map((item) => (
                  <div
                    key={item.id}
                    style={{
                      padding: "10px",
                      fontSize: "20px",
                      cursor: "pointer",
                      backgroundColor:
                        selectedItem.id === item.id
                          ? "rgb(138, 177, 184)"
                          : "transparent",
                    }}
                    onClick={() => setSelectedItem(item)}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
              {/* <div style={{ flex: 1, padding: "20px" }}> */}
              <ContentContainer>
                {selectedItem.id === 1 && (
                  <Row
                    style={{
                      margin: "20px",
                      gap: "50px",
                    }}
                  >
                    <Column>
                      <Styled.StyledLabel>Address</Styled.StyledLabel>
                      <br />
                      <Styled.StyledInput
                        id="address"
                        name="address"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address || ""}
                      />
                      <br />

                      <Styled.StyledLabel htmlFor="tagline">
                        Tagline
                      </Styled.StyledLabel>
                      <br />
                      <Styled.StyledTextArea
                        id="tagline"
                        name="tagline"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.tagline || ""}
                      />
                      <br />

                      <Styled.StyledLabel htmlFor="description">
                        Description
                      </Styled.StyledLabel>
                      <br />
                      <Styled.StyledTextArea
                        id="description"
                        name="description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                        style={{ height: "200px" }}
                      />
                    </Column>
                    <Column>
                      {/* <label htmlFor="amenities">Amenities</label>
                  <input
                    id="amenities"
                    name="amenities"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.amenities}
                  /> */}
                      {/* <ErrorMessage name="amenities" component="div" /> */}
                      {/* <label htmlFor="photos">Photos</label>
                  <input
                    id="photos"
                    name="photos"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.photos}
                  /> */}
                      {/* <ErrorMessage name="photos" component="div" /> */}
                      {/* <label htmlFor="thumbnail">Thumbnail</label>
                  <input
                    id="thumbnail"
                    name="thumbnail"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.thumbnail}
                  /> */}
                      {/* <ErrorMessage name="thumbnail" component="div" /> */}
                      <Row style={{ gap: "10px" }}>
                        <Column>
                          <Styled.StyledLabel htmlFor="numAllowedAdults">
                            Number of Adults Allowed
                          </Styled.StyledLabel>
                          <Styled.StyledInput
                            id="numAllowedAdults"
                            name="numAllowedAdults"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.numAllowedAdults}
                          />
                        </Column>
                        <Column>
                          <Styled.StyledLabel htmlFor="numAllowedChildren">
                            Number of Children Allowed
                          </Styled.StyledLabel>
                          <Styled.StyledInput
                            id="numAllowedChildren"
                            name="numAllowedChildren"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.numAllowedChildren}
                          />
                        </Column>
                      </Row>

                      <Row style={{ gap: "10px" }}>
                        <Column>
                          <Styled.StyledLabel htmlFor="numBedrooms">
                            Number of Bedrooms
                          </Styled.StyledLabel>
                          <Styled.StyledInput
                            id="numBedrooms"
                            name="numBedrooms"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.numBedrooms}
                          />
                        </Column>
                        <Column>
                          <Styled.StyledLabel htmlFor="numBathrooms">
                            Number of Bathrooms
                          </Styled.StyledLabel>
                          <Styled.StyledInput
                            id="numBathrooms"
                            name="numBathrooms"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.numBathrooms}
                          />
                        </Column>
                      </Row>
                      <Row style={{ gap: "10px" }}>
                        <Column>
                          <Styled.StyledLabel htmlFor="numBeds">
                            Number of Beds
                          </Styled.StyledLabel>
                          <Styled.StyledInput
                            id="numBeds"
                            name="numBeds"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.numBeds}
                          />
                        </Column>
                        <Column>
                          <Styled.StyledLabel htmlFor="cleaningFee">
                            Cleaning Fee
                          </Styled.StyledLabel>

                          <Styled.StyledInput
                            id="cleaningFee"
                            name="cleaningFee"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.cleaningFee}
                          />
                        </Column>
                      </Row>

                      {/* <label htmlFor="owner">Owner</label>
                  <input
                    id="owner"
                    name="owner"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.owner}
                  /> */}
                    </Column>
                  </Row>
                )}
                {selectedItem.id === 2 && (
                  <Row
                    id="booking-content"
                    style={{ justifyContent: "space-between", height: "100%" }}
                  >
                    <GlobalStyles />
                    <Column
                      style={{
                        width: "600px",
                        padding: "20px",
                      }}
                    >
                      <BookingPanel>
                        <StyledTexts.Title>Add Booking</StyledTexts.Title>
                        <Row>
                          <Column>
                            <Calendar
                              name="startDate"
                              value={startDate}
                              onChange={(e) =>
                                handleStartDateChange(e.value ? e.value : null)
                              }
                              placeholder="Check-In Date"
                              dateFormat="mm/dd/yy"
                              disabledDates={disabledDates}
                              minDate={new Date()}
                              dateTemplate={dateTemplate}
                              // Other optional properties...
                            />
                            {errors.startDate && (
                              <div className="error">{errors.startDate}</div>
                            )}
                          </Column>
                          <Column>
                            <Calendar
                              name="endDate"
                              value={endDate}
                              onChange={(e) =>
                                handleEndDateChange(e.value ? e.value : null)
                              }
                              placeholder="Checkout Date"
                              dateFormat="mm/dd/yy"
                              minDate={new Date()}
                              disabledDates={disabledDates}
                              dateTemplate={dateTemplate}
                            />
                          </Column>
                        </Row>
                        {errors.endDate && <div>{errors.endDate}</div>}
                        <br />

                        <Styled.StyledLabel>Guest name</Styled.StyledLabel>
                        <Row>
                          <Styled.StyledInput
                            name="guestName"
                            value={guestName}
                            onChange={handleGuestNameChange}
                          />
                          {errors.guestName && <div>{errors.guestName}</div>}
                        </Row>
                        <span
                          style={{
                            justifyContent: "right",
                            alignItems: "right",
                            alignContent: "right",
                          }}
                        >
                          <StyledComponents.PrimaryButton
                            style={{
                              width: "150px",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                              alignSelf: "right",
                              color: "white",
                            }}
                            onClick={addDateRange}
                          >
                            Add Booking
                          </StyledComponents.PrimaryButton>
                        </span>
                      </BookingPanel>
                    </Column>

                    <Column>
                      <StyledTexts.Title
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        Bookings to be Added:
                      </StyledTexts.Title>

                      {dateRanges.map((dateRange: booking, index: number) => (
                        <Container key={index}>
                          {dateRange.startDate.toLocaleDateString()} -{" "}
                          {dateRange.endDate.toLocaleDateString()}:{" "}
                          {dateRange.guestName}
                          <button
                            type="button"
                            onClick={() => removeDateRange(index)}
                          >
                            {""}
                            Remove
                          </button>
                        </Container>
                      ))}
                    </Column>
                    <Column
                      style={{
                        height: "100%",
                        borderLeft: "1px dotted #5d5d5d",
                      }}
                    >
                      <div style={{ marginLeft: "10px" }}>
                        <StyledTexts.Title>
                          Existing Bookings:
                        </StyledTexts.Title>
                        <Container
                          style={{
                            overflowY: "auto",
                            maxHeight: "400px",
                            border: "1px solid #ccc",
                            width: "400px",
                            padding: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          {renderSortedAndGroupedBookings()}
                        </Container>
                      </div>
                    </Column>

                    {/* <Column>
                      <StyledTexts.Title>Existing Bookings</StyledTexts.Title>
                      <br />
                      {formik.values.bookings &&
                        formik.values.bookings.map(
                          (booking: booking, index: number) => (
                            <div
                              key={index}
                              style={{
                                textDecoration: markedForDeletion.includes(
                                  index
                                )
                                  ? "line-through"
                                  : "none",
                              }}
                            >
                              {new Date(booking.startDate).toLocaleDateString()}{" "}
                              - {new Date(booking.endDate).toLocaleDateString()}{" "}
                              {booking.guestName}
                              {markedForDeletion.includes(index) ? (
                                <button
                                  type="button"
                                  onClick={() => undoDeletion(index)}
                                >
                                  Undo
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => markForDeletion(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          )
                        )}
                    </Column> */}
                  </Row>
                )}
              </ContentContainer>
            </div>
          </form>{" "}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default EditProperty;
