import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Title } from "../styledTexts";
import { Row, Column } from "../ui/layout";
// Styled component for the gallery container
const GalleryContainer = styled.div`
  width: 100%;
  height: 95%;
  position: relative;
  display: flex; // Correctly apply flexbox
  justify-content: center; // Horizontally center children
  align-items: center; // Vertically center children
  text-align: center;
  overflow: hidden;

  @media (max-width: 768px) {
  }
`;

const ImageContainer = styled.div`
  justify-content: center; // Centers the image horizontally
  align-items: center; // Centers the image vertically
  height: 400px;
  @media (max-width: 768px) {
    height: 500px;
  }
`;

// Styled component for the images
const Image = styled.img`
  width: 800px;
  min-width: 300px;
  max-height: 600px;
  // height: 40%;
  position: absolute;
  top: 90px;
  right: 100px;
  justify-content: center;
  justify-self: center;
  align-self: center;

  opacity: 0;
  transition: opacity 1s ease-in-out;
  object-fit: cover; // Maintains the aspect ratio, adjust as needed
  object-position: center;

  &.active {
    opacity: 1;
  }
  @media (max-width: 1024px) {
    // position: relative;
    // left: 0;
    width: 100%;
    height: 98%;
    max-height: 100%;
    left: 0;
  }

  @media (max-width: 768px) {
    right: 0;
    height: 300px;
    width: 100%;
    top: 2%;
    max-height: auto;
    padding: 10px;
    z-index: 12;
  }
`;

// Array of image URLs
const images = [
  "misphotos/105front.jpeg",
  "misphotos/105ocean.jpeg",
  "misphotos/401flag.jpeg",
  "185sunset.jpg",
  "185lake.jpeg",
  "185flag.jpg",
  "301inside.jpeg",
  // Add more image paths here
];

const Blurb = styled.div`
  background: rgba(204, 204, 204, 0.7);
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  position: absolute;
  z-index: 10;
  width: 800px;
  height: 400px;
  left: 100px;
  top: 200px;
  overflow: visible;
  @media (max-width: 1024px) {
    position: absolute;
    width: 80%;
    height: 40%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(204, 204, 204, 0.7);
  }
  @media (max-width: 768px) {
    // Remove transform and adjust positioning as needed for smaller screens
    transform: none; // This line removes the transform effect
    // top: auto; // Adjust if needed to position the blurb differently
    bottom: 0; // Example to move it to the bottom. Adjust as per your design.
    left: 0; // Adjust to reposition as needed
    width: 100%; // Adjust width to fit the screen
    height: auto; // Adjust height as needed
    background: #faf9ee;
    top: 300px;
    padding-top: 0;
  }
`;

const ModifiedTitle = styled(Title)`
  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: 0;
  }
`;

const MainTitle = styled(Title)`
  font-size: 50px;
  @media (max-width: 768px) {
    margin-top: 0;
    font-size: 35px;
  }
`;

// Gallery component
function Gallery() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <GalleryContainer>
      <ImageContainer>
        {images.map((image, index) => (
          <Image
            key={image}
            src={image}
            alt={`Slide ${index}`}
            className={index === activeIndex ? "active" : ""}
          />
        ))}
      </ImageContainer>

      <Blurb>
        <Row style={{ justifyContent: "center" }}>
          {" "}
          <MainTitle> Welcome to Cape Ocean Vista,</MainTitle>
        </Row>
        <Row style={{ marginTop: "0px" }}>
          <ModifiedTitle>
            Your gateway to unforgettable getaways. Nestled in the heart of Cape
            Cod, our collection of vacation rentals is designed to make your
            dream vacation a reality.
          </ModifiedTitle>
        </Row>
      </Blurb>
    </GalleryContainer>
  );
}

export default Gallery;
