import { housesDict } from "../HouseInfo";
import Property from "../../components/Property/Property";

function LakeOne() {
  return (
    <div>
      <Property
        {...{
          housesDict: housesDict[185],
          propertyId: "65c270d93886dd758c1690fc",
        }}
      />

      {/* <Property {...housesDict[185]} /> */}
    </div>
  );
}

export default LakeOne;
