import styled from "styled-components";
import DatePicker from "react-datepicker";

export const PropertyContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

export const Gallery = styled.div`
  width: 100%;
  height: 500px; // or set a specific height, e.g., height: 500px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 10px; // Add a gap if you want space between the main and sub-images
  position: relative; // This is crucial
`;

export const GalleryMainImage = styled.div`
  flex: 1; // You can adjust the flex value to make this div wider or narrower
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; // This will stretch the image to fill the container
  }
`;

export const GallerySubImages = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ShowAllButton = styled.button`
  width: 100%;
  padding: 10px;
  text-align: center;
  border: none;
  background-color: #039be5;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: darken(#039be5, 10%);
  }
`;

export const PropertyBody = styled.div`
  width: 100%;

  height: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PropertyBodyInfoContainer = styled.div`
  max-width: 800px;
  flex: 1.8;
  padding-right: 50px;

  @media (max-width: 768px) {
    padding-right: 0px;
  }
`;

export const PropertyBodyBookingContainer = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const PropertyBodyBookingForm = styled.form`
  border: 1px solid #ccc;
  position: sticky;
  top: 10%;
  border-radius: 10px;
  justify-content: center;
  padding: 5px;
  margin: 10px;
  h1 {
    text-align: center;
  }
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.6);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
`;

export const Column = styled.div`
  width: 100%;
  flex: 1;
`;

export const StyledInput = styled.input<{ $inputColor?: string }>`
  padding: 0.75em;
  margin: 0.75em;
  width: 100%;
  color: ${(props) => props.$inputColor || "#000000"};
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 2px solid #ccc;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 15px;
`;

export const StyledDatePicker = styled(DatePicker)`
  padding: 0.75em;
  margin: 0.75em;
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 2px solid #ccc;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 13px;

  /* add more styles as needed */
`;

export const StyledSelect = styled.select<{ $inputColor?: string }>`
  padding: 0.75em;
  margin: 0.75em;
  width: 100%;
  color: ${({ theme }) => theme.textColors.primary};
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 2px solid #ccc;
  border-radius: 3px;
  appearance: none; // This line is important to remove default styling
  background-image: url("path-to-your-dropdown-arrow-image"); // Optional: Custom dropdown arrow
  background-repeat: no-repeat;
  background-position: right 0.75em top 50%, 0 0; // Adjust this as needed
  background-size: 1.5em 1.5em; // Adjust size of dropdown arrow
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 15px;
`;

export const StyledTextArea = styled.textarea`
  padding: 0.75em;
  margin: 0.75em;
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 2px solid #ccc;
  border-radius: 3px;
  min-height: 100px;
  font-family: Roboto, sans-serif;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 16px;
  color: ${({ theme }) => theme.textColors.primary};
`;

export const LinedDiv = styled.div`
  position: relative;
  /* Other styles for your div */
  //   &::after
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 2px; /* Thickness of the line */
    background: #e1e1e1; /* Color of the line */
    margin-top: -10px;
  }

  &::before {
    top: 0; /* Position at the top */
  }

  &::after {
    bottom: 0; /* Position at the bottom */
  }
`;

export const StyledGrid = styled.div<{ columns: string }>`
  display: grid;
  grid-template-columns: ${(props) => props.columns};

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  display: table;
  align-items: space-between;
  justify-content: center;
  border-collapse: collapse; /* Removes the space between borders */
  text-align: left;
  margin-top: 20px; /* Adjust as needed */
  font-family: ${({ theme }) => theme.fonts.primary};

  th {
    // width: 200px;
    background-color: #5e8b94;
    color: white;
    padding: 10px;
    border: 1px solid #ccc; /* Adjust or remove as needed */
  }

  td {
    width: 200px;
    padding: 10px;
    border: 1px solid #ccc;
  }

  tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Zebra striping for rows */
  }
`;

export const ViewAllButton = styled.button`
  width: 100%;
  padding: 10px;
  text-align: center;
  border: none;
  background-color: ${({ theme }) => theme.buttonColors.primary};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px; /* Adjust as needed */
  font-family: ${({ theme }) => theme.fonts.primary};

  &:hover {
    background-color: ${({ theme }) => theme.buttonColors.primaryHover};
  }
`;
