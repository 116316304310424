import { housesDict } from "../HouseInfo";
import Property from "../../components/Property/Property";

function CondoTwoSix() {
  return (
    <div>
      {/* <Property {...housesDict[206]} /> */}
      <Property
        {...{
          housesDict: housesDict[206],
          propertyId: "65c26c0e3886dd758c1690fa",
        }}
      />
    </div>
  );
}

export default CondoTwoSix;
