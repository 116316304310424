import styled from "styled-components";
import DatePicker from "react-datepicker";

export const PropertyBodyBookingForm = styled.form`
  max-width: 800px;
  border: 1px solid #ccc;
  position: sticky;
  top: 10%;
  border-radius: 10px;
  justify-content: center;
  padding: 5px;
  margin: 10px;
  h1 {
    text-align: center;
  }
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.6);
`;

export const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
`;

export const Column = styled.div`
  width: 100%;
  flex: 1;
`;

export const StyledInput = styled.input<{ $inputColor?: string }>`
  flex: 1;
  padding: 0.75em;
  margin: 0.75em;
  margin-bottom: 0.4rem;
  width: 100%;
  color: ${(props) => props.$inputColor || "#000000"};
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 15px;
  border-color: ${({ theme }) => theme.textColors.secondary};
`;

export const ErrorMessage = styled.div`
  margin-left: 0.75em;
  margin-right: 0.75em;
  padding-right: 0.75em;
  padding-left: 0.75em;
  flex: 1;
  color: red;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const StyledDatePicker = styled(DatePicker)`
  padding: 0.75em;
  margin: 0.75em;
  margin-bottom: 0.4rem;
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 13px;
  color: ${({ theme }) => theme.textColors.primary};
  border-color: ${({ theme }) => theme.textColors.secondary};

  /* add more styles as needed */
`;

export const StyledSelect = styled.select<{ $inputColor?: string }>`
  padding: 0.75em;
  margin: 0.75em;
  width: 100%;
  color: ${({ theme }) => theme.textColors.primary};
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 1px solid #ccc;
  border-radius: 3px;
  appearance: none; // This line is important to remove default styling
  background-image: url("path-to-your-dropdown-arrow-image"); // Optional: Custom dropdown arrow
  background-repeat: no-repeat;
  background-position: right 0.75em top 50%, 0 0; // Adjust this as needed
  background-size: 1.5em 1.5em; // Adjust size of dropdown arrow
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 15px;
  border-color: ${({ theme }) => theme.textColors.secondary};
`;

export const StyledTextArea = styled.textarea`
  padding: 0.75em;
  margin: 0.75em;
  margin-bottom: 0.5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 1px solid #ccc;
  border-radius: 3px;
  min-height: 100px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  color: ${({ theme }) => theme.textColors.primary};
  text-color: ${({ theme }) => theme.textColors.primary};
  border-color: ${({ theme }) => theme.textColors.secondary};

  ::placeholder {
    color: ${({ theme }) =>
      theme.textColors.primary}; // Replace with your desired color
    opacity: 1; // For some browsers that reduce the opacity of placeholder
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center; // Vertically aligns the children
  justify-content: flex-start; // Aligns children to the start (left)
  padding-left: 0.8rem;
  background-color: ${({ theme }) => theme.backgroundColor};
  :checked:before {
    background-color: #00aeef;
  }
`;

// export const StyledLabel = styled.label`
//   margin-left: 0.5em; // Space between the checkbox and label
//   font-family: ${({ theme }) => theme.fonts.primary};
//   background-color: ${({ theme }) => theme.backgroundColor};

//   // Add more styling as needed
// `;

export const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  margin: 0.75em;
  padding: 0.75em;
  width: 20px; // Adjust the size as needed
  height: 20px; // Adjust the size as needed
  border: 1px solid #ccc;
  border-radius: 3px;

  ::before {
    background-color: #00aeef;
  }
  :checked:before {
    background-color: #00aeef;
  }

  // // Custom checkmark style
  &:checked {
    background-color: ${({ theme }) =>
      theme.buttonColors.primary}; // Add a custom checkmark icon if you want
    background-position: center;
    background-size: 16px 16px; // Adjust size as needed
  }

  // // Customize the focus outline color
  &:hover {
    outline: none;
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4); // Adjust the color and size as needed
  }

  background-color: #000000;
  background-color: ${({ theme }) => theme.backgroundColor};
  :checked::before {
    background-color: #00aeef;
  }

  // You can add more styles for hover, active, etc.
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

export const StyledLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 15px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;

  &:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    bottom: 1px;
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.textColors.secondary};
  }

  ${HiddenCheckbox}:checked + &::before {
    content: "\u2714";
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    color: ${({ theme }) => theme.buttonColors.primary};
    text-align: center;
    line-height: 12px;
  }

  &:hover::before,
  ${HiddenCheckbox}:hover + &::before {
    border-color: ${({ theme }) =>
      theme.buttonColors.primary}; // Change to the desired blue color
    outline: 2px solid ${({ theme }) => theme.buttonColors.primary}; // Add a thick blue outline on hover
  }
`;
