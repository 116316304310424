import { housesDict } from "../HouseInfo";
import Property from "../../components/Property/Property";
import { useState, useEffect } from "react";
import { PropertyAPI } from "../../api";
import PropertyType from "../../types/PropertyType";

function OceanOne() {
  const [property, setProperty] = useState<PropertyType | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const property1 = await PropertyAPI.getProperty(
          "65b72d55dd746fdf7f06cfff"
        );
        setProperty(property1);
      } catch (error) {
        console.error("Error fetching property data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* {property && ( */}
      <Property
        {...{
          housesDict: housesDict[105],
          propertyId: "65b72d55dd746fdf7f06cfff",
        }}
      />
      {/* )} */}
      {/* <Property {...{ housesDict: housesDict[105] }} /> */}
    </div>
  );
}
export default OceanOne;
