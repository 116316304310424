import { housesDict } from "../HouseInfo";
import Property from "../../components/Property/Property";

function CondoFourOne() {
  return (
    <div>
      {/* <Property {...housesDict[401]} /> */}
      <Property
        {...{
          housesDict: housesDict[401],
          propertyId: "65c1b95f3886dd758c1690a4",
        }}
      />
    </div>
  );
}

export default CondoFourOne;
