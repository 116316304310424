import React, { createContext, useContext, useState } from "react";
import User from "../types/UserType";

type ReactChildrenProps = {
  children: React.ReactNode;
};

// Define the shape of the context data for better type-checking
interface AuthContextType {
  isLogged: boolean;
  login: (user: User) => void;
  logout: () => void;
}

// Initialize the context with the expected shape
const AuthContext = createContext<AuthContextType>({
  isLogged: false,
  login: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<ReactChildrenProps> = ({ children }) => {
  const [isLogged, setIsLogged] = useState(!!localStorage.getItem("userId"));

  const login = (user: User) => {
    localStorage.setItem("userId", user.email);
    setIsLogged(true);
  };

  const logout = () => {
    localStorage.removeItem("userId");
    setIsLogged(false);
  };

  return (
    <AuthContext.Provider value={{ isLogged, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
