import styled from "styled-components";

export const Heading = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.5rem;
  color: ${({ theme }) => theme.textColors.primary};
  margin: 0;
  transition: color 0.5s ease;
  padding: 0;

  @media (max-width: 768px) {
    display: block; // Or you can remove this line as h1 is block by default
  }
`;

export const HighlightedLink = styled.a<{ color: string }>`
  color: ${({ color }) => color};
  padding: 0px 2px;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: inset 0 -4px 0 ${({ color }) => color};

  &:hover {
    color: ${({ color }) => color};
    text-decoration: none !important;
    cursor: pointer;
    box-shadow: inset 0 -55px 0 0 ${({ color }) => color};
  }
`;

// export const MenuItem = styled.li<{ mode: "light" | "dark" }>`
//   font-family: ${({ theme }) => theme.fonts.heading};
//   color: ${({ theme }) => theme.texts.primary};
//   transition: background-color 0.3s ease, color 0.3s ease;

//   &:hover {
//     // Define hover state styles
//     background-color: ${({ theme }) => theme.hover_color};
//   }
// `;

export const MenuItem = styled.li<{ mode: "light" | "dark" }>`
  font-size: 6rem;
  font-family: ${({ theme }) => theme.fonts.heading};
  position: relative; // Essential for absolute positioning of pseudo-elements
  list-style: none; // Removing default list style
  cursor: pointer;
  overflow: hidden; // Keeps pseudo-element within the bounds of the li

  a {
    color: ${({ theme }) => theme.texts.primary};
    text-decoration: none;
  }

  // Before pseudo-element for the fill effect
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0; // Start with no width
    height: 100%;
    background-color: ${({ theme }) =>
      theme.hover_color}; // Choose your fill color

    transition: width 0.3s ease; // Animate the width
    z-index: -1;
  }

  &:hover::before {
    width: 100%; // Full width on hover
  }

  // Adjust text color or other properties as needed
  color: ${({ theme }) => theme.texts.primary};
`;

export const ResumeCardContainer = styled.div`
  padding-top: 50px;
  background-color: ${({ theme }) => theme.backgroundColor};
  transition: background-color 0.5s ease;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const ResumeCardRow = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftColumn = styled.div`
  padding: 10px;
  flex: 25%;
`;

export const RightColumn = styled.div`
  align-items: center;
  flex: 75%;
  padding: 10px;
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResumeDetails = styled.div`
  padding-bottom: 5px;
  @media (max-width: 768px) {
    padding-bottom: 10px;
  }
`;

export const MainRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the left */

    > div {
      width: auto; /* Allows each div to shrink to its content size */
      margin: 0; /* Optional: Adjusts margin for a tighter layout */
    }
  }
`;

interface HeadingProps {
  flexDirection?: string;
}

export const SubRow = styled.div<HeadingProps>`
  display: flex;
  justify-content: ${({ flexDirection }) => flexDirection || "space-between"};
  gap: 1px;

  margin: 2px;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
