import styled from "styled-components";
import DatePicker from "react-datepicker";

export const StyledLabel = styled.label<{ $inputColor?: string }>`
  padding: 0.75em;
  margin: 0.75em;
`;

export const StyledInput = styled.input<{ $inputColor?: string }>`
  flex: 1;
  padding: 0.75em;
  margin: 0.75em;
  width: 100%;
  color: ${(props) => props.$inputColor || "#000000"};
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 15px;
  border-color: ${({ theme }) => theme.textColors.secondary};
`;

export const StyledTextArea = styled.textarea<{ $width?: string }>`
  padding: 0.75em;
  margin: 0.75em;
  width: ${({ $width }) => $width || "100%"};
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 1px solid #ccc;
  border-radius: 3px;
  min-height: 100px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  color: ${({ theme }) => theme.textColors.primary};
  text-color: ${({ theme }) => theme.textColors.primary};
  border-color: ${({ theme }) => theme.textColors.secondary};

  ::placeholder {
    color: ${({ theme }) =>
      theme.textColors.primary}; // Replace with your desired color
    opacity: 1; // For some browsers that reduce the opacity of placeholder
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  padding: 0.75em;
  margin: 0.75em;
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 13px;
  color: ${({ theme }) => theme.textColors.primary};
  border-color: ${({ theme }) => theme.textColors.secondary};

  /* add more styles as needed */
`;
