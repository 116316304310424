import React, { useState } from "react";
import axios from "axios";
import { UserAPI } from "../api";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/Navbar";
import styled from "styled-components";
import { SubmitButton } from "../styledComponents";
import { Title } from "../styledTexts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginPageContainer = styled.div`
  height: 100vh;
`;

const LoginContainer = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  display: flex;
  width: 400px;
  border: 1px solid #ccc;
  flex-direction: column;
  border-radius: 15px;
  margin: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
`;

const Label = styled.label`
  margin-left: 1.2rem;
  margin-top: 0.75rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 20px;
`;

const Input = styled.input`
  flex: 1;
  padding: 0.75em;
  margin: 0.75em;
  width: 90%;

  background-color: ${({ theme }) => theme.backgroundColor};
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 15px;
  border-color: ${({ theme }) => theme.textColors.secondary};
  align-self: center;
`;

const StyledSubmitButton = styled(SubmitButton)`
  align-self: center;
  width: 90%;
`;

const LoginTitle = styled(Title)`
  text-align: center;
`;

const LoginComponent: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { login } = useAuth();

  const handleLogin = async () => {
    try {
      console.log("Logging in user", { email, password });
      const response = await UserAPI.loginUser(email, password);
      console.log("User logged in", response.data);

      // Handle successful login here
      const user = { email: email };
      login(user);
      navigate("/owner-view");
    } catch (error: any) {
      console.log("Error logging in", error);
      showToastMessage(error.response.data.message);
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An unexpected error occurred");
      }
    }
  };

  const showToastMessage = (error: string) => {
    toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <LoginPageContainer>
      <NavBar />
      <LoginContainer>
        <ToastContainer />
        <LoginBox>
          <LoginTitle>Owner Login</LoginTitle>
          <Label>Email</Label>
          <Input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Label>Password</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <StyledSubmitButton onClick={handleLogin}>Login</StyledSubmitButton>
          {/* {errorMessage && <p>{errorMessage}</p>} */}
        </LoginBox>
      </LoginContainer>
    </LoginPageContainer>
  );
};

export default LoginComponent;
