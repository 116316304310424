import React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const PresentationContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  width: 100%;
  display: flex;
  overflow: hidden;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SlideInLeftPanel = styled.div`
  width: 50%;
  height: 100%;
  animation: slideInRight 1s forwards;
  display: flex;
  align-items: center;
  justify-content: right;

  img {
    margin-right: 5rem;
  }

  @media (max-width: 768px) {
    width: 100vw;
    justify-content: left;
    img {
      margin-left: 0rem;
    }
  }

  @keyframes slideInRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
`;

const SlideInRightPanel = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;

  img {
    margin-left: 5rem;
  }
  animation: slideInLeft 1s forwards;

  @media (max-width: 768px) {
    width: 100vw;
    justify-content: center;

    img {
      margin-left: 0rem;
    }
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
`;

type MyComponentProps = {
  LeftSide: React.ReactNode;
  RightSide: React.ReactNode;
};

const LeftRightSlideContainer: React.FC<MyComponentProps> = ({
  LeftSide,
  RightSide,
}) => {
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <PresentationContainer ref={ref}>
      {inView ? <SlideInLeftPanel>{LeftSide}</SlideInLeftPanel> : null}
      {inView ? <SlideInRightPanel>{RightSide}</SlideInRightPanel> : null}
    </PresentationContainer>
  );
};

export default LeftRightSlideContainer;
