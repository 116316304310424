import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row; // Change this to column to stack children vertically
  width: 100%; // Ensure it takes the full width of its parent, if necessary
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  //   width: 100%;
  flex: 1;
`;

export const Container = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
`;
