import axios from "axios";
import { SendEmailEndpoint } from "../config";
import { Base } from "../config";

export const sendEmail = (body) => {
  const response = axios.post(SendEmailEndpoint, body);
  // return { status: 500, error: "Error sending email" };
  // return response;
  if (response.status === 200) {
    return { data: response.data, status: 200, error: null };
  } else {
    return {
      data: response.data,
      status: 500,
      error: "Error sending inquiry email",
    };
  }
};

export const sendConfirmationEmail = async (data) => {
  const response = await axios.post(`${Base}/email/sendEmail`, data);
  // return { status: 200, error: "Error sending confirmation email" };
  if (response.status === 200) {
    return { data: response.data, status: 200, error: null };
  } else {
    return {
      data: response.data,
      status: 500,
      error: "Error sending confirmation email",
    };
  }
};
