import styled from "styled-components";

interface ButtonProps {
  color?: string;
  backgroundColor?: string;
}

export const PrimaryButton = styled.div<ButtonProps>`
  background-color: ${({ theme }) => theme.backgroundColors.secondary};
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme, color }) => color || theme.secondaryColor};
  transition: all 0.3s ease-in-out;
  font-family: ${({ theme }) => theme.fontFamily};

  a {
    text-decoration: none;
    color: ${({ theme, color }) => color || theme.textColors.primary};
    font-style: bold;
  }
  > * {
    text-decoration: none;
  }
  &:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
`;

interface ButtonProps {
  color?: string;
  backgroundColor?: string;
}

export const SlidingButton = styled.button<ButtonProps>`
  display: inline-block;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.backgroundColors.primary};
  border: 1px solid ${({ theme }) => theme.backgroundColors.secondary};
  color: ${({ color, theme }) => color || theme.backgroundColors.secondary};
  text-align: center;
  font-size: 18px;
  padding: 5px;
  width: 200px;
  padding-left: 0px;

  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-left: 0px;

  span {
    color: ${({ theme }) => theme.textColors.primary};
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    // color: ${({ color, theme }) =>
      color || theme.backgroundColors.secondary};
    color: #244c54;
    font-weight: bold;

    &:after {
      content: "\\00bb";
      position: absolute;
      opacity: 0;
      top: 0;
      right: -20px;
      transition: 0.5s;
    }
  }

  &:hover span {
    padding-right: 25px;
  }

  &:hover span:after {
    opacity: 1;
    right: 0;
  }
`;

export const SeeMoreButton = styled.button<ButtonProps>`
  display: inline-block;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.backgroundColors.secondary};
  border: none;
  color: ${({ color, theme }) => color || theme.textColors.tertiary};
  text-align: center;
  font-size: 18px;
  padding: 5px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;

  span {
    color: ${({ theme }) => theme.textColors.tertiary};
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;

    &:after {
      content: "\\00bb";
      position: absolute;
      opacity: 0;
      top: 0;
      right: -20px;
      transition: 0.5s;
    }
  }

  &:hover span {
    padding-right: 25px;
  }

  &:hover span:after {
    opacity: 1;
    right: 0;
  }
`;

export const SubmitButton = styled.button`
  box-sizing: border-box; // Add this line
  font-family: ${({ theme }) => theme.fonts.primary};

  background-color: ${({ theme }) => theme.buttonColors.primary};
  padding: 0.75em;
  margin: 0.75em;
  width: 100%;
  border: 2px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.buttonColors.primaryHover};
  }
  border: none;
  outline: none;
  color: #fff;
  font-size: 22px;
  border-radius: 40px;
  text-align: center;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.buttonColors.primaryHover};
  }
`;

interface FlexProps {
  align?: string;
  justify?: string;
}

// Use the interface with your styled component
export const Row = styled.div<FlexProps>`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  // align-items: ${({ align }) => align || "center"};
  // justify-content: ${({ justify }) => justify || "flex-start"};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div<FlexProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "flex-start"};
`;
