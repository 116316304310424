import axios from "axios";
import { Base } from "../config";

export const loginUser = async (email, password) => {
  const response = await axios.post(`${Base}/user/loginUser`, {
    email,
    password,
  });
  if (response.status === 200) {
    return response.data;
  }
};
