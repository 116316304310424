type HouseInfo = {
  propertyId: string;
  tagline: string;
  description: string;
  photos: string[];
  flagPhoto: string;
  route: string;
  allowedAdults: number;
  allowedChildren?: number;
  allowed_guests: number;
  num_bedrooms: number;
  num_beds: number;
  num_baths: number;
  amenities: amenities;
  priceScheme?: pricingSchemeDictionary[];
};

type HouseDictionary = {
  [key: number]: HouseInfo;
};

type pricingSchemeDictionary = {
  [key: string]: string;
};

type amenities = {
  essentials?: string[];
  kitchen?: string[];
  entertainment?: string[];
};

const houses: HouseInfo[] = [
  {
    propertyId: "C001",
    tagline:
      "Stunning Oceanfront Home With Amazing Views ~ Private Lovely Beach Area",
    description:
      "Stunning oceanfront custom built house with spectacular ocean views. Wake up to the sounds of the ocean and gorgeous view every morning, enjoy sunsets on your deck, take a stroll on miles of sandy beach. The house was custom built in 1997 by Premier McPhee builders and has top-of-the-line quality custom finishes, an incredible gourmet kitchen with granite counter top, Subzero refrigerator, and Viking stoves. It has central air conditioning, high speed internet, and HDTV. It is professionally cleaned and linens/bath towels are provided.",
    photos: [
      "../../misphotos/105front.jpeg",
      "../../105/0165661b.jpg",
      "../../105/31962631.jpg",
      "../../105/936f5c02.jpg",
      "../../105/06b90f7d.jpg",
      "../../105/373314a5.jpg",
      "../../105/9702033d.jpg",
      "../../105/083b17b3.jpg",
      "../../105/48192afb.jpg",
      "../../105/9a2ce032.jpeg",
      "../../105/0de35e4c.jpg",
      "../../105/484c2a26.jpg",
      "../../105/9c9d97ae.jpg",
      "../../105/0fcade34.jpg",
      "../../105/4e3e173f.jpg",
      "../../105/9cffda80.jpg",
      "../../105/123f6a94.jpg",
      "../../105/55b2dc5a.jpg",
      "../../105/9db07b59.jpg",
      "../../105/1580ee72.jpg",
      "../../105/72a11fef.jpg",
      "../../105/9fd0e889.jpg",
      "../../105/16c7c695.jpg",
      "../../105/75ebbd7f.jpg",
      "../../105/ab176c42.jpg",
      "../../105/17ef8e06.jpg",
      "../../105/7753b3b6.jpg",
      "../../105/c83bd717.jpg",
      "../../105/180-e3bf8a94.jpg",
      "../../105/78e9b66b.jpg",
      "../../105/c8d8403f.jpg",
      "../../105/18f25ae5.jpg",
      "../../105/799792ee.jpg",
      "../../105/cc21f0d3.jpg",
      "../../105/1ec47869.jpg",
      "../../105/7a335be0.jpg",
      "../../105/d58ba93f.jpg",
      "../../105/1fb25af8.jpg",
      "../../105/7b098d74.jpg",
      "../../105/d64f227e.jpg",
      "../../105/2288d332.jpg",
      "../../105/7c37d667.jpg",
      "../../105/dbe888fb.jpg",
      "../../105/235e6075.jpg",
      "../../105/80d0236c.jpg",
      "../../105/dc4bc9ed.jpg",
      "../../105/270-1fec51f7.jpg",
      "../../105/818f0de3.jpg",
      "../../105/df1c9869.jpg",
      "../../105/270-32164972.jpg",
      "../../105/8cc6559f.jpg",
      "../../105/f6142d3a.jpg",
      "../../105/270-3cc25ee2.jpg",
      "../../105/8f01d72d.jpg",
      "../../105/f6aaeb92.jpg",
      "../../105/2ec3ab5a.jpeg",
      "../../105/8f2ec8f1.jpg",
    ],

    flagPhoto: "misphotos/105front.jpeg",
    route: "/properties/ocean-one",
    allowedAdults: 8,
    allowedChildren: 5,
    allowed_guests: 10,
    num_bedrooms: 3,
    num_beds: 6,
    num_baths: 5,
    amenities: {
      essentials: [
        "wireless internet",
        "towels",
        "bed sheets",
        "soap",
        "toilet paper",
        "Heating",
        "Air conditioning",
        "Washer",
        "Dryer",
        "Hot water",
        "Hair dryer",
        "Iron",
        "Laptop friendly workspace",
        "Private entrance",
        "Smoke alarm",
        "Carbon monoxide alarm",
        "Fire extinguisher",
      ],
      kitchen: [
        "Refrigerator",
        "Microwave",
        "Dishwasher",
        "Dishes and silverware",
        "Cooking basics",
        "Oven",
        "Stove",
        "Coffee maker",
        "Toaster",
        "Ice Maker",
        "Grill",
      ],
      entertainment: [
        "TV",
        "Indoor fireplace",
        "Outdoor firepit",
        "Puzzles and games",
        "Books",
        "Pool table",
        "Foosball table",
      ],
    },
    priceScheme: [
      {
        period: "Jan 2 - Jan 11",
        nightly: "$549",
      },
      {
        period: "Jan 12 - Jan 15",
        nightly: "$699",
      },
      {
        period: "Jan 16 - Feb 29",
        nightly: "$549",
      },
      {
        period: "Mar 1 - May 28",
        nightly: "$549",
        weekend_night: "$559",
      },
      {
        period: "Mar 29 - Apr 4",
        nightly: "$569",
      },
      {
        period: "Apr 5 - Apr 6",
        nightly: "$599",
      },
      {
        period: "Apr 7 - Apr 11",
        nightly: "$579",
      },
      {
        period: "Apr 12 - Apr 20",
        nightly: "$619",
        weekend_night: "$639",
      },
      {
        period: "Apr 21 - Apr 27",
        nightly: "$609",
        weekend_night: "$659",
      },
      {
        period: "Apr 28 - May 4",
        nightly: "$629",
        weekend_night: "$699",
      },
      {
        period: "May 5 - May 11",
        nightly: "$749",
        weekend_night: "$799",
      },
      {
        period: "May 12 - May 18",
        nightly: "$849",
        weekend_night: "$899",
      },
      {
        period: "May 19 - May 23",
        nightly: "$949",
      },
      {
        period: "May 24 - May 27",
        nightly: "$1400",
      },
      {
        period: "May 28 - May 31",
        nightly: "$999",
      },
      {
        period: "Jun 1 - Jun 7",
        nightly: "$999",
      },
      {
        period: "Jun 8 - Jun 14",
        nightly: "$1300",
      },
      {
        period: "Jun 15 - Jun 21",
        nightly: "$1400",
      },
      {
        period: "Jun 15 - Jun 21",
        nightly: "$1400",
        min_stay: "7",
      },
      {
        period: "Jun 22 - Jun 28",
        nightly: "$1500",
        min_stay: "7",
      },
      {
        period: "Jun 29 - Jul 5",
        nightly: "$1790",
        min_stay: "7",
      },
      {
        period: "Jul 5 - Aug 30",
        nightly: "$1720",
        min_stay: "7",
      },
      {
        period: "Aug 31 - Sept 13",
        nightly: "$1600",
        min_stay: "7",
      },
      {
        period: "Sept 14 - Sept 21",
        nightly: "$1200",
        min_stay: "7",
      },
      {
        period: "Sept 22 - Sept 28",
        nightly: "$1500",
        min_stay: "7",
      },
      {
        period: "Sept 29 - Oct 5",
        nightly: "$1100",
        min_stay: "7",
      },
      {
        period: "Oct 6 - Oct 10",
        nightly: "$1040",
      },
      {
        period: "Oct 1 - Oct 14",
        nightly: "$1100",
      },
      {
        period: "Oct 15 - Oct 19",
        nightly: "$999",
      },
      {
        period: "Oct 20 - Oct 25",
        nightly: "$899",
      },
      {
        period: "Oct 26 - Nov 1",
        nightly: "$799",
      },
      {
        period: "Nov 2 - Nov 8",
        nightly: "$749",
      },
      {
        period: "Nov 9 - Nov 15",
        nightly: "$699",
      },
      {
        period: "Nov 16 - Nov 19",
        nightly: "$899",
      },
      {
        period: "Nov 20 - Nov 24",
        nightly: "$110",
      },
      {
        period: "Nov 25 - Nov 30",
        nightly: "$699",
      },
      {
        period: "Dec 1 - Dec 19",
        nightly: "$599",
      },
      {
        period: "Dec 20 - Dec 31",
        nightly: "$1100",
      },
    ],
  },
  {
    propertyId: "C004",
    tagline:
      "Cape Cod Oceanfront Beachfront New Penthouse w Ocean View & Private Sandy Beach!",
    description:
      "Brand new 2021 Penthouse: spectacular 180-degree panoramic views of the ocean and private ocean beach on Nantucket Sound. Living, dining, and kitchen directly facing the ocean! Wake up to the sound of the ocean waves and gorgeous view every morning, just steps away from the private ocean beach! Master Bedroom has an ocean view with a queen sized bed, an en-suite full bath with shower/tub combo, a large window, and a smart TV; Bedroom #2 also has an ocean view with a full sized bed and a bunk bed, large window, a smart TV, and a full bath with a shower. In the living room a large sliding glass doors opening to the super-sized balcony with a spectacular 180-degree panoramic view of Nantucket sound. Beautiful kitchen with eat-in area overlooking ocean. An incredible waterfall quartz island. Subzero refrigerator, wolf stove, microwave, wine cooler, and stainless steel appliances. Living room has a smart TV. Central AC, central heating, Washer and Dryer inside the penthouse. Linens and bath towels are provided. The unit is located on the fourth floor. The building has an elevator and stairs.",
    photos: [
      "../../misphotos/401flag.jpeg",
      "../../401/00243eea.jpg",
      "../../401/0223d953.jpg",
      "../../401/087ce1ae.jpg",
      "../../401/0e257f25.jpg",
      "../../401/12d0a4f5.jpg",
      "../../401/19689768.jpg",
      "../../401/234402a7.jpg",
      "../../401/28231526.jpg",
      "../../401/2fff9dc1.jpg",
      "../../401/303be957.jpg",
      "../../401/30e4f373.jpg",
      "../../401/32e5e08b.jpg",
      "../../401/35a901e1.jpg",
      "../../401/3a419c3d.jpg",
      "../../401/3a46d49e.jpg",
      "../../401/3bca0207.jpg",
      "../../401/403b4f7b.jpg",
      "../../401/471e165b.jpg",
      "../../401/48e8a7f8.jpg",
      "../../401/4a385144.jpg",
      "../../401/54823000.jpg",
      "../../401/5962fe43.jpg",
      "../../401/596f1d71.jpg",
      "../../401/5f708596.jpg",
      "../../401/621fd2cf.jpg",
      "../../401/6577b88e.jpg",
      "../../401/6f62447b.jpg",
      "../../401/733198cc.jpg",
      "../../401/77578d2a.jpg",
      "../../401/77c70694.jpg",
      "../../401/7f65f742.jpg",
      "../../401/8be2ca4a.jpg",
      "../../401/8fc14c1e.jpg",
      "../../401/90a80ce9.jpg",
      "../../401/9d39da20.jpg",
      "../../401/a7ecc702.jpg",
      "../../401/a99eaabe.jpg",
      "../../401/aa6c7cb9.jpg",
      "../../401/ab2e9af6.jpg",
      "../../401/ab883824.jpg",
      "../../401/ad02222c.jpg",
      "../../401/aed9de07.jpg",
      "../../401/bfa477b0.jpg",
      "../../401/c1118146.jpg",
      "../../401/c56a5a7b.jpg",
      "../../401/cb1e6dcb.jpg",
      "../../401/cf9b8ae6.jpg",
      "../../401/d1d7b5f3.jpg",
      "../../401/e1851df5.jpg",
      "../../401/e829a30c.jpg",
      "../../401/e92f539c.jpg",
      "../../401/eb41cc5f.jpg",
      "../../401/efc1d536.jpg",
      "../../401/fe3db5f6.jpg",
    ],
    flagPhoto: "misphotos/401flag.jpeg",
    route: "/properties/condo-four",
    allowedAdults: 6,
    allowed_guests: 6,
    num_bedrooms: 2,
    num_beds: 4,
    num_baths: 2,
    amenities: {
      essentials: [
        "Wireless Internet",
        "Air conditioning",
        "Heating",
        "Washer",
        "Dryer",
        "Hot water",
        "Toilet paper",
        "Basic soaps",
        "Clean linens provided",
        "Laptop friendly workspace",
        "Smoke alarm",
        "Carbon monoxide alarm",
      ],
      kitchen: [
        "Subzero refrigerator",
        "Wolf stove",
        "Microwave",
        "Dishwasher",
        "Dishes and silverware",
        "Cooking basics",
        "Oven",
        "Coffee maker",
        "Toaster",
        "Kettle",
        "Blender",
        "Gas Grill",
        "Wine cooler",
        "Dining table",
      ],
      entertainment: ["Books", "Smart TV", "Jenga"],
    },
  },
  {
    propertyId: "C003",
    tagline:
      "Cape Cod Oceanfront Beachfront New Condo with Ocean View & Private Sandy Beach!",
    description:
      "Brand new 2021 condo: spectacular ocean view and private ocean beach on Nantucket Sound. Living, dining, and kitchen directly facing the ocean! Wake up to the sound of the ocean waves and gorgeous view every morning, just steps away from the private ocean beach! Bedroom #1 has an ocean view with a queen sized bed, an ensuite full bath with shower, and a large window; Bedroom #2 also has an ocean view with a queen sized bed, large window, and a smart TV. Another full bath with shower/tub combo is in the hallway. Large sliding glass doors opening to the large balcony with a spectacular view of Nantucket sound. Kitchen with eat-in area, beautiful countertops, and stainless steel appliances overlooking ocean view. Living room has a smart TV. Central AC, central Heating, Washer and Dryer inside own unit. Linens and bath towels are provided. The unit is located on the third floor. The building has an elevator and stairs.",
    photos: [
      "../301inside.jpeg",
      "../../301/028bdf62.jpg",
      "../../301/27c3e67d.jpg",
      "../../301/5901df54.jpg",
      "../../301/7db914de.jpg",
      "../../301/9824f5d8.jpg",
      "../../301/b8d1a14f.jpg",
      "../../301/cad7d79e.jpg",
      "../../301/f5eb57a7.jpg",
      "../../301/03b5cf2e.jpg",
      "../../301/28231526.jpg",
      "../../301/5f708596.jpg",
      "../../301/7e1cd63b.jpg",
      "../../301/aa8fe426.jpg",
      "../../301/ba923609.jpg",
      "../../301/cf015cd5.jpg",
      "../../301/fa81a9cf.jpg",
      "../../301/0bddeb70.jpg",
      "../../301/2b1f461d.jpg",
      "../../301/61c03318.jpg",
      "../../301/822b0788.jpg",
      "../../301/ac1f8131.jpg",
      "../../301/bb8563aa.jpg",
      "../../301/d1d7b5f3.jpg",
      "../../301/0d0d81c1.jpg",
      "../../301/2cf417a2.jpg",
      "../../301/61f92890.jpg",
      "../../301/87616015.jpg",
      "../../301/aca0c7b9.jpg",
      "../../301/bbdad711.jpg",
      "../../301/d6b97b19.jpg",
      "../../301/0e6fbfc6.jpg",
      "../../301/46f7b7c8.jpg",
      "../../301/6ca9ca3e.jpg",
      "../../301/8b3e9acd.jpg",
      "../../301/ae2af51f.jpg",
      "../../301/c2d550da.jpg",
      "../../301/dbb6b82a.jpg",
      "../../301/19689768.jpg",
      "../../301/4a9deca8.jpg",
      "../../301/6f113e3d.jpg",
      "../../301/92b49757.jpg",
      "../../301/b00d96a2.jpg",
      "../../301/c3899605.jpg",
      "../../301/e99744fd.jpg",
      "../../301/2040d4c9.jpg",
      "../../301/527cd9d2.jpg",
      "../../301/7d6d64e9.jpg",
      "../../301/94b80f3f.jpg",
      "../../301/b58c61ae.jpg",
      "../../301/c8b36277.jpg",
      "../../301/f00b10ec.jpg",
    ],
    flagPhoto: "misphotos/301flag.jpeg",
    route: "/properties/condo-three",
    allowedAdults: 4,
    allowedChildren: 3,
    allowed_guests: 4,
    num_bedrooms: 2,
    num_beds: 2,
    num_baths: 2,
    amenities: {
      essentials: [
        "Wireless Internet",
        "Air conditioning",
        "Heating",
        "Washer",
        "Dryer",
        "Hot water",
        "Toilet paper",
        "Basic soaps",
        "Clean linens provided",
        "Laptop friendly workspace",
        "Smoke alarm",
        "Carbon monoxide alarm",
      ],
      kitchen: [
        "Refrigerator",
        "Microwave",
        "Dishwasher",
        "Dishes and silverware",
        "Cooking basics",
        "Oven",
        "Coffee maker",
        "Toaster",
        "Kettle",
        "Blender",
        "Gas Grill",
        "Dining table",
      ],
      entertainment: ["Books", "Smart TV"],
    },
  },
  {
    propertyId: "C002",
    tagline:
      "Cape Cod Oceanfront Beachfront Condo with Ocean View & Private Sandy Beach!",
    description:
      "A condo brand new in 2013 with spectacular ocean view and private ocean beach on Nantucket Sound! You wake up to the sound of the ocean wave and gorgeous view every morning! Just steps away from the private ocean beach! Master bedroom and kitchen facing ocean directly! Large sliding glass doors from Master bedroom opening to large balcony with spectacular view of Nantucket sound. Master bedroom and guest bedroom each has its own bathroom. Kitchen with eat-in area, granite counter top, and stainless steel appliances overlooking ocean view. Living room with gas fireplace and flat screen TV (Comcast Xfinity). Master Bedroom has a Roku TV. Gleaming hardwood floors. Washer and Dryer inside own unit. End unit on the second floor.",
    photos: [
      "../206view.jpg",
      "../../206/2.jpeg",
      "../../206/3.jpeg",
      "../../206/4.jpeg",
      "../../206pictures/08b1649d.jpg",
      "../../206pictures/1812ff3a.jpg",
      "../../206pictures/403b4f7b.jpg",
      "../../206pictures/4cf9fdff.jpg",
      "../../206pictures/8577201f.jpg",
      "../../206pictures/a00a16e9.jpg",
      "../../206pictures/b8a2ae5f.jpg",
      "../../206pictures/dca42fdd.jpg",
      "../../206pictures/0900726a.jpg",
      "../../206pictures/1c9b10c7.jpg",
      "../../206pictures/40e00b2e.jpg",
      "../../206pictures/5f708596.jpg",
      "../../206pictures/8629663a.jpg",
      "../../206pictures/a6114d7f.jpg",
      "../../206pictures/bd64e33e.jpg",
      "../../206pictures/f34008ef.jpg",
      "../../206pictures/0b3ead88.jpg",
      "../../206pictures/2355c243.jpg",
      "../../206pictures/41a57cbf.jpg",
      "../../206pictures/6132a9af.jpg",
      "../../206pictures/8745adc5.jpg",
      "../../206pictures/a7ecc702.jpg",
      "../../206pictures/bf6a9cbd.jpg",
      "../../206pictures/f5eb57a7.jpg",
      "../../206pictures/0c39717b.jpg",
      "../../206pictures/24c5c203.jpg",
      "../../206pictures/43772569.jpg",
      "../../206pictures/6f62447b.jpg",
      "../../206pictures/87be120d.jpg",
      "../../206pictures/a9272ef6.jpg",
      "../../206pictures/c5d9ee91.jpg",
      "../../206pictures/0d0d81c1.jpg",
      "../../206pictures/35f1de93.jpg",
      "../../206pictures/48f83622.jpg",
      "../../206pictures/7d87f95f.jpg",
      "../../206pictures/8f885555.jpg",
      "../../206pictures/a942aa4c.jpg",
      "../../206pictures/d1b55cf1.jpg",
      "../../206pictures/0e1541d3.jpg",
      "../../206pictures/3b2102a3.jpg",
      "../../206pictures/494f822f.jpg",
      "../../206pictures/806a760f.jpg",
      "../../206pictures/96f4ac70.jpg",
      "../../206pictures/acfd36f5.jpg",
      "../../206pictures/d1d7b5f3.jpg",
      "../../206pictures/0ff1b62f.jpg",
      "../../206pictures/3b4f45cf.jpg",
      "../../206pictures/4bcd6a1e.jpg",
      "../../206pictures/82362104.jpg",
      "../../206pictures/9a9a2cd3.jpg",
      "../../206pictures/ad02222c.jpg",
      "../../206pictures/d6efb064.jpg",
    ],
    flagPhoto: "206view.jpg",
    route: "/properties/condo-two",
    allowedAdults: 10,
    allowedChildren: 1,
    allowed_guests: 4,
    num_bedrooms: 2,
    num_beds: 2,
    num_baths: 2,
    amenities: {
      essentials: [
        "Wireless Internet",
        "Air conditioning",
        "Heating",
        "Washer",
        "Dryer",
        "Hot water",
        "Toilet paper",
        "Basic soaps",
        "Clean linens provided",
        "Laptop friendly workspace",
        "Smoke alarm",
        "Carbon monoxide alarm",
      ],
      kitchen: [
        "Refrigerator",
        "Microwave",
        "Dishwasher",
        "Dishes and silverware",
        "Cooking basics",
        "Oven",
        "Coffee maker",
        "Toaster",
        "Kettle",
        "Blender",
        "Gas Grill",
        "Dining table",
      ],
      entertainment: ["Books", "Smart TV"],
    },
  },
  // ... additional houses can be added in the same format
  {
    propertyId: "C005",
    tagline:
      "Beautiful Waterfront Property with a Beach, Dock, Deck, and Fabulous Water Views",
    description: `A spectacular waterfront property! Privately situated at the end of a quiet lane, this home sits on a lush two acres featuring commanding views and frontage on Follins Pond/Bass River with a circular drive way. Enter the front door and be struck by the open grand room featuring cathedral ceilings, and windows overlooking the large patio, beautiful lawn, and fabulous view of the waters. The one level house has 8 rooms with 4 bedrooms and 3 full bathrooms. Enjoy beautiful views of the water from almost every room in the house. The kitchen and a guest bathroom were completely remodeled in 2019. New high efficient Fujitsu Air Conditioning Systems were installed for the whole house in 2019 as well. There is a TV in entertainment room and another TV in master bedroom.

      The property includes a sandy beach that is great for swimming. A canoe, two kayaks, and numerous other water accessories including beach chairs, lifejackets, and paddles are also provided.
      Additionally, the property includes a dock and a boathouse with a full bath right on the edge of the water with a newly built deck around.
      An inviting opportunity for anyone looking to enjoy the incomparable natural amenities of Cape Cod in a beautiful location.`,
    photos: [
      "../185flag.jpg",
      "../../185/2.jpeg",
      "../../185/3.jpeg",
      "../../185/4.jpeg",
      "../../185/5.jpeg",
      "../../185pictures/0988aa92.jpg",
      "../../185pictures/270-8daae9d2.jpg",
      "../../185pictures/52ee60e8.jpg",
      "../../185pictures/9317d059.jpg",
      "../../185pictures/c9a8ef50.jpg",
      "../../185pictures/0a3b2532.jpg",
      "../../185pictures/270-9f018522.jpg",
      "../../185pictures/648023df.jpg",
      "../../185pictures/9611441a.jpg",
      "../../185pictures/ca626c8f.jpg",
      "../../185pictures/0d6dde60.jpg",
      "../../185pictures/270-a6f7cffa.jpg",
      "../../185pictures/6d65495e.jpg",
      "../../185pictures/9688c599.jpg",
      "../../185pictures/d945a0c6.jpg",
      "../../185pictures/0f3eb674.jpg",
      "../../185pictures/3346d992.jpg",
      "../../185pictures/7443b54e.jpg",
      "../../185pictures/9deeb4f0.jpg",
      "../../185pictures/dbc8c3fd.jpg",
      "../../185pictures/180-00466a8c.jpg",
      "../../185pictures/389e8d9f.jpg",
      "../../185pictures/795bcddf.jpg",
      "../../185pictures/a63a7fa3.jpg",
      "../../185pictures/de0a444d.jpg",
      "../../185pictures/180-6dafa173.jpg",
      "../../185pictures/41134994.jpg",
      "../../185pictures/7c2f5aa3.jpg",
      "../../185pictures/a7041174.jpg",
      "../../185pictures/e025ad29.jpg",
      "../../185pictures/180-8ade453b.jpg",
      "../../185pictures/4275f5f8.jpg",
      "../../185pictures/804ab66c.jpg",
      "../../185pictures/aaefcf64.jpg",
      "../../185pictures/e342f642.jpg",
      "../../185pictures/180-c59733df.jpg",
      "../../185pictures/42968939.jpg",
      "../../185pictures/84369f2f.jpg",
      "../../185pictures/b48bdf83.jpg",
      "../../185pictures/e8b1e76e.jpg",
      "../../185pictures/1d3cf050.jpg",
      "../../185pictures/44427f22.jpg",
      "../../185pictures/87cd71ad.jpg",
      "../../185pictures/b4f23de8.jpg",
      "../../185pictures/ea7dd620.jpg",
      "../../185pictures/270-34ae759f.jpg",
      "../../185pictures/4a3113c2.jpg",
      "../../185pictures/89c6e0e9.jpg",
      "../../185pictures/b6b334e3.jpg",
      "../../185pictures/ed60a5d1.jpg",
      "../../185pictures/270-471fb7d0.jpg",
      "../../185pictures/4eea2a65.jpg",
      "../../185pictures/8c12b5c6.jpg",
      "../../185pictures/bf904033.jpg",
      "../../185pictures/270-532e8a01.jpg",
      "../../185pictures/4f8488a0.jpg",
      "../../185pictures/8eaaa4aa.jpg",
      "../../185pictures/c36b5b73.jpg",
    ],
    flagPhoto: "185flag.jpg",
    route: "/properties/lake-one",
    allowedAdults: 8,
    allowedChildren: 7,
    allowed_guests: 8,
    num_bedrooms: 4,
    num_beds: 5,
    num_baths: 4,
    amenities: {
      essentials: [
        "Wireless Internet",
        "Heating",
        "Washer",
        "Dryer",
        "Hot water",
        "Toilet paper",
        "Basic soaps",
        "Clean linens provided",
        "Laptop friendly workspace",
        "Smoke alarm",
        "Carbon monoxide alarm",
      ],
      kitchen: [
        "Refrigerator",
        "Microwave",
        "Dishwasher",
        "Dishes and silverware",
        "Cooking basics",
        "Oven",
        "Coffee maker",
        "Toaster",
        "Kettle",
        "Blender",
        "Gas Grill",
        "Dining table",
      ],
      entertainment: [
        "Books",
        "Smart TV",
        "Puzzles and games",
        "DVD player",
        "Kayaking",
        "Canoeing",
        "Fishing",
        "Swimming",
        "Beach chairs",
        "Beach towels",
        "Beach umbrellas",
        "Life jackets",
        "Paddle boards",
        "Paddles",
      ],
    },
  },
  // ... additional houses can be added in the same format
];

export const housesDict: HouseDictionary = {
  105: houses[0],
  401: houses[1],
  301: houses[2],
  206: houses[3],
  185: houses[4],
  // ... you can add more houses following the same structure
};

export default houses;
