export interface Theme {
  backgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
  fontFamily: string;
  fonts: {
    primary: string;
    secondary: string;
  };
  backgroundColors: {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
  };
  textColors: {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
  };
  buttonColors: {
    primary: string;
    primaryHover: string;
    secondary: string;
  };

  // add more theme properties here
}

// Example theme
export const lightTheme: Theme = {
  backgroundColor: "#FAF9EE",
  primaryColor: "#fff",
  secondaryColor: "#000",
  fontFamily: "Rubik",
  fonts: {
    primary: "Rubik",
    secondary: "Cormorant Garamond",
  },
  backgroundColors: {
    primary: "#FAF9EE",
    secondary: "#5E8B95",
    tertiary: "#000",
    quaternary: "#000",
  },
  textColors: {
    primary: "#000",
    secondary: "grey",
    tertiary: "#ffffff",
    quaternary: "#000",
  },
  buttonColors: {
    primary: "#5e8b94",
    primaryHover: "#3b656d",
    secondary: "#25515a",
  },
  // more theme properties
};
export const darkTheme: Theme = {
  backgroundColor: "#fff",
  primaryColor: "#fff",
  secondaryColor: "#000",
  fontFamily: "Libre Caslon Text",
  fonts: {
    primary: "Playfair Display",
    secondary: "Libre Caslon Text",
  },
  backgroundColors: {
    primary: "#fff",
    secondary: "grey",
    tertiary: "#000",
    quaternary: "#000",
  },
  textColors: {
    primary: "#000",
    secondary: "grey",
    tertiary: "#000",
    quaternary: "#000",
  },
  buttonColors: {
    primary: "#5e8b94",
    primaryHover: "#3b656d",
    secondary: "#25515a",
  },
  // more theme properties
};
