import styled from "styled-components";

interface TextProps {
  fontSize?: string;
  color?: string;
  margin?: string;
}

export const Title = styled.h1<TextProps>`
  font-size: ${({ fontSize }) =>
    fontSize || "1.5em"}; // Use fontSize prop, default to 1.5em if not provided
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ color, theme }) => color || theme.textColors.primary};
  a {
    color: ${({ theme }) => theme.textColors.primary};
  }
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const SubTitle = styled.h2<TextProps>`
  font-size: ${({ fontSize }) => fontSize || "1.25em"};
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ color, theme }) => color || theme.textColors.secondary};
`;

export const BodyText = styled.p<TextProps>`
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ color, theme }) => color || theme.textColors.primary};
  a {
    font-size: 1.2rem;
  }
`;
