import { housesDict } from "../HouseInfo";
import Property from "../../components/Property/Property";

function CondoThreeOne() {
  return (
    <div>
      {/* <Property {...housesDict[301]} /> */}
      <Property
        {...{
          housesDict: housesDict[301],
          propertyId: "65c26b953886dd758c1690f8",
        }}
      />
    </div>
  );
}

export default CondoThreeOne;
