import Home from "./pages/Home";
import { ThemeProvider } from "./contexts/ThemeContext";
import CondoFour from "./pages/properties/CondoFourOne";
import CondoThree from "./pages/properties/CondoThreeOne";
import CondoTwo from "./pages/properties/CondoTwoSix";
import OceanOne from "./pages/properties/OceanOneFive";
import LakeOne from "./pages/properties/LakeOne";
import Contact from "./pages/ContactPage";
import EditProperty from "./pages/EditProperty";
import ScrollToTop from "./ScrollToTop";
import Footer from "./components/Footer";
import { AuthProvider } from "./contexts/AuthContext";
import Login from "./pages/Login";
import { Analytics } from "@vercel/analytics/react";
import OwnerView from "./pages/OwnerView";

import "primereact/resources/themes/saga-blue/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GlobalStyle from "./styles/GlobalStyle";

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <GlobalStyle />
        <Analytics />
        <Router>
          <ScrollToTop />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/properties/condo-four" element={<CondoFour />} />
            <Route path="/properties/condo-three" element={<CondoThree />} />
            <Route path="/properties/condo-two" element={<CondoTwo />} />
            <Route path="/properties/ocean-one" element={<OceanOne />} />
            <Route path="/properties/lake-one" element={<LakeOne />} />
            <Route path="/owner-view" element={<OwnerView />} />
            <Route
              path="/owner-view/property/edit/:propertyId"
              element={<EditProperty />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer />
        </Router>
      </AuthProvider>

    </ThemeProvider>
  );
}

export default App;
