import axios from "axios";
import { Base } from "../config";

export const sendSMS = async (data) => {
  const response = await axios.post(`${Base}/notify/sendSMS`, data);
  if (response.status === 200) {
    return { data: response.data, status: 200, error: null };
  } else {
    return {
      data: response.data,
      status: 500,
      error: "Error sending SMS",
    };
  }
  //   return { status: 200, error: "Error sending SMS" };
  //   return { status: 500, error: "Error sending SMS" };
};
