import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MobileMenu from "./MenuIcon";
import { useAuth } from "../contexts/AuthContext";
import * as StyledTexts from "../styledTexts";

const NavBarContainer = styled.nav`
  padding-left: 3rem;
  padding-right: 3rem;
  height: 10vh;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.primaryColor};
  background-color: ${({ theme }) => theme.backgroundColors.primary};
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 768px) {
    padding-top: 0.5rem;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const NavBarLinksContainer = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  gap: 1.5rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavBarItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.backgroundColors.primary};
  transition: color 0.2s ease, background-color 0.2s ease;

  a {
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme }) => theme.textColors.primary};
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.2s ease, background-color 0.5s ease;
  }

  &:hover > div {
    display: flex;
  }
`;

const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  top: 100%; // This positions the dropdown right below the link
  // right: 5px; // Aligns the dropdown to the start of the NavBarItemContainer
  transform: translateX(
    -10%
  ); // Shifts the dropdown to the left; adjust as needed
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundColors.primary};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1100;
  min-width: 160px; // Ensure it's wide enough to hold the content
  border: 1px solid ${({ theme }) => theme.textColors.secondary};
`;

const DropdownItem = styled.div`
  padding: 12px 16px;
  font-size: 1rem;
  color: ${({ theme }) => theme.textColors.primary};
  &:hover {
    background-color: ${({ theme }) => theme.backgroundColors.secondary};
  }
`;

const NavBarPhotoContainer = styled.div`
  flex: 1;
`;

const StyledImg = styled.img`
  max-width: 200px;

  @media (max-width: 768px) {
    max-width: 150px;
  }
`;

const OwnerViewLink = styled(NavBarItemContainer)`
  border: 1px solid #5f8c95;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColors.secondary};
  }

  &:hover > a {
    color: ${({ theme }) => theme.textColors.tertiary};
  }

  // Ensure DropdownMenu does not inherit the hover background color
  ${DropdownMenu} {
    background-color: ${({ theme }) =>
      theme.backgroundColors.primary} !important;
  }
`;

interface NavBarProps {
  logoSrc?: string; // Optional prop
}

function NavBar({ logoSrc = "../horizontal_logo.jpg" }: NavBarProps) {
  const { isLogged, logout } = useAuth();

  return (
    <NavBarContainer>
      <NavBarPhotoContainer>
        <Link to="/">
          <StyledImg src={logoSrc} alt="logo" />
        </Link>
      </NavBarPhotoContainer>

      <NavBarLinksContainer>
        <NavBarItemContainer>
          <Link to="/">Home</Link>
        </NavBarItemContainer>

        <NavBarItemContainer>
          <Link to="/contact">Contact Yiping Ding</Link>
        </NavBarItemContainer>
        <NavBarItemContainer>
          <StyledTexts.BodyText>
            <a href="tel:+1-617-416-8883">1 (617) 416-8883</a>
          </StyledTexts.BodyText>
        </NavBarItemContainer>
        <NavBarItemContainer>
          <StyledTexts.BodyText>
            <a href="mailto:capeoceanvista@gmail.com">
              capeoceanvista@gmail.com
            </a>
          </StyledTexts.BodyText>
        </NavBarItemContainer>
        <OwnerViewLink
          style={{
            border: "1px solid #5F8C95",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          {isLogged ? (
            <>
              <Link to="/owner-view">Owner View</Link>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/owner-view">Owner View</Link>
                </DropdownItem>
                <DropdownItem
                  onClick={logout}
                  style={{
                    cursor: "pointer",
                    fontSize: "1.2rem",
                  }}
                >
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </>
          ) : (
            <Link to="/login">Owner Login</Link>
          )}
        </OwnerViewLink>
      </NavBarLinksContainer>
      <MobileMenu />
    </NavBarContainer>
  );
}

export default NavBar;
