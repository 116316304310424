import axios from "axios";
import { Base } from "../config";

export const getProperty = async (id) => {
  const response = await axios.get(`${Base}/property/getProperty/${id}`);
  if (response.status === 200) {
    return response.data.property;
  }
};

export const getProperties = async () => {
  const response = await axios.get(`${Base}/property/getProperties`);
  if (response.status === 200) {
    return response.data.properties;
  } else {
    console.log("no properties found");
    return [];
  }
};

export const createProperty = async (property) => {
  const response = await axios.post(
    `${Base}/property/createProperty`,
    property
  );
  if (response.status === 201) {
    return response.data;
  }
};

export const updateProperty = async (id, property) => {
  console.log("updateProperty", id, property);
  const response = await axios.put(
    `${Base}/property/updateProperty?id=${id}`,
    property
  );
  if (response.status === 201) {
    return response.data;
  }
  console.log("response", response);
};
