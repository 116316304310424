import { useState, useEffect, useRef } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { SlidingButton } from "../styledComponents";
import styled from "styled-components";
import FadeInSection from "..//FadeInSection";
import { FaHouse } from "react-icons/fa6";
import { housesDict } from "../pages/HouseInfo";
import { Link } from "react-router-dom";

const ImageContainer = styled.div`
  width: 100%; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

const MapButton = styled.button`
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: #ff0000;
  z-index: 2;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  /* Add any additional styles for your buttons */
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 3s ease-in-out, visibility 3s ease-in-out,
    background 1s ease-in-out;
`;

const ModalContent = styled.div`
  width: 400px;
  height: 600px;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 3s ease-in-out;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const ModalText = styled.h1`
  font-size: 1.5em;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.textColors.primary};
`;

const ModalImageContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CloseIcon = styled(IoCloseOutline)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #000; // or any color you prefer
  font-size: 1.5em;
  border: 1px solid #555; // Add border if needed
  border-radius: 50%;
  &:hover {
    color: #555; // Change color on hover if needed
    border-color: #555; // Change border color on hover if needed
  }
`;

// Define a type for the button position
type ButtonPosition = {
  id: number;
  top: number;
  left: number;
};

function Map() {
  const [buttonPositions, setButtonPositions] = useState<ButtonPosition[]>([]);
  const [activeFeature, setActiveFeature] = useState<number | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [showModal, setShowModal] = useState(false);

  // Define your features here with their original positions
  const features = [
    { id: 105, x: 2000, y: 2175 }, // Feature 1 position
    { id: 401, x: 2255, y: 2175 }, // Feature 2 position
    { id: 301, x: 2200, y: 2150 }, // Feature 3 position
    { id: 185, x: 1900, y: 2050 }, // Feature 4 position
    { id: 206, x: 2200, y: 2200 }, // Feature 5 position
  ];

  const updateButtonPositions = () => {
    const image = imageRef.current;
    if (!image) return;

    const originalImageWidth = 3400; // Original width
    const originalImageHeight = 4200; // Original height

    const containerWidth = image.clientWidth;
    const containerHeight = image.clientHeight;

    const scale = Math.max(
      containerWidth / originalImageWidth,
      containerHeight / originalImageHeight
    );

    const newButtonPositions = features.map((feature) => {
      const displayedImageWidth = originalImageWidth * scale;
      const displayedImageHeight = originalImageHeight * scale;

      const croppedWidth = Math.max(
        0,
        (displayedImageWidth - containerWidth) / 2
      );
      const croppedHeight = Math.max(
        0,
        (displayedImageHeight - containerHeight) / 2
      );

      const visibleFeatureX = feature.x * scale - croppedWidth;
      const visibleFeatureY = feature.y * scale - croppedHeight;

      return {
        id: feature.id,
        top: visibleFeatureY,
        left: visibleFeatureX,
      };
    });

    setButtonPositions(newButtonPositions);
  };

  useEffect(() => {
    const handleResize = () => {
      updateButtonPositions();
    };

    window.addEventListener("resize", handleResize);
    // Update position once the image is loaded
    if (imageRef.current) {
      imageRef.current.onload = updateButtonPositions;
    }

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (featureId: number) => {
    setActiveFeature(featureId);
    setShowModal(true); // Show modal on button click
  };

  const handleClose = () => {
    setActiveFeature(null);
    setShowModal(false); // Hide modal on close
  };

  return (
    <div className="App">
      <ImageContainer>
        <img ref={imageRef} src="higherres.jpeg" alt="Map" />
        {buttonPositions.map((position) => (
          <MapButton
            key={position.id}
            onClick={() => handleClick(position.id)}
            style={{
              top: `${position.top}px`,
              left: `${position.left}px`,
            }}
          >
            <FaHouse />
            {/* Info {position.id} */}
          </MapButton>
        ))}
      </ImageContainer>
      {activeFeature !== null && (
        <ModalBackdrop
          onClick={handleClose}
          style={{
            opacity: showModal ? 1 : 0,
            visibility: showModal ? "visible" : "hidden",
          }}
        >
          <FadeInSection>
            <ModalContent
              onClick={(e) => e.stopPropagation()}
              style={{ opacity: showModal ? 1 : 0 }}
            >
              <CloseIcon onClick={handleClose} />
              <ModalText>{housesDict[activeFeature].tagline}</ModalText>
              <ModalImageContainer>
                <img src={housesDict[activeFeature].flagPhoto} alt="house" />
              </ModalImageContainer>
              <SlidingButton style={{ verticalAlign: "middle" }}>
                <Link to={housesDict[activeFeature].route}>
                  <span>View details</span>
                </Link>
              </SlidingButton>
            </ModalContent>
          </FadeInSection>
        </ModalBackdrop>
      )}
    </div>
  );
}

export default Map;
