import React, { useState } from "react";
import styled from "styled-components";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

// Define an interface for your DropdownMenu props
interface DropdownMenuProps {
  isOpen: boolean;
}

const MenuIcon = styled.div`
  display: none; // Hide by default
  top: 10px;
  right: 10px;
  z-index: 100; // Above other content
  transition: color 0.5s ease;
  cursor: pointer;
  background-color: ${({ theme }) => theme.backgroundColor};

  @media (max-width: 768px) {
    display: block; // Only show on small screens
  }
`;

// Use the interface for the styled component
const DropdownMenu = styled.div<DropdownMenuProps>`
  display: none;
  left: 0;
  right: 0;
  width: 100vw;
  background-color: ${({ theme }) => theme.backgroundColor};
  box-sizing: border-box;
  z-index: 100;
  position: absolute;
  top: 10vh;
  overflow: hidden; // Hide the overflow when the menu is closed
  max-height: ${({ isOpen }) =>
    isOpen ? "300px" : "0"}; // Adjust the max height as needed
  transition: max-height 0.3s ease-in-out, background-color 0.5s ease; // Smooth transition for the max-height

  a {
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.primary};
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    flex: none;
    transition: background-color 0.5s ease, color 0.5s ease;
    color: ${({ theme }) => theme.textColors.primary};

    &:hover {
      background-color: ${({ theme }) => theme.backgroundColors.secondary};
    }
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

// The component
const MobileMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLogged, logout } = useAuth();

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleNavItemClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <MenuIcon onClick={handleMenuClick}>
        <RxHamburgerMenu color="black" size={39} />
      </MenuIcon>
      <DropdownMenu isOpen={isOpen}>
        {/* List your menu items here */}
        <a href="/" onClick={handleNavItemClick}>
          Home
        </a>
        <a href="/contact" onClick={handleNavItemClick}>
          Contact Property Manager
        </a>
        <a href="tel:+1-617-416-8883">1 (617) 416-8883</a>

        <a href="mailto:capeoceanvista@gmail.com">capeoceanvista@gmail.com</a>
        {isLogged ? (
          <Link to="/owner-view">Owner View</Link>
        ) : (
          <Link to="/login" onClick={handleNavItemClick}>
            Owner Login
          </Link>
        )}
      </DropdownMenu>
    </>
  );
};

export default MobileMenu;
