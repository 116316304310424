import "./PhotoGroup.css";
import styled from "styled-components";
import FadeInSection from "../../FadeInSection";
import {
  ResumeCardContainer,
  ResumeCardRow,
  LeftColumn,
  RightColumn,
  Heading,
} from "./styles";

const ResumeCardTitle = styled(Heading)`
  color: ${({ theme }) => theme.hover_color};
  font-size: 1.5rem;
  position: sticky;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 2rem;
  }
`;

const StickyContainer = styled.div`
  top: 0;
  position: sticky;
`;

type ResumeCardProps = {
  section_name: string;
  photo_content: JSX.Element;
};

function PhotoGroup({ section_name, photo_content }: ResumeCardProps) {
  return (
    <ResumeCardContainer>
      <ResumeCardRow>
        <LeftColumn>
          <StickyContainer>
            <FadeInSection>
              <ResumeCardTitle>{section_name}</ResumeCardTitle>
            </FadeInSection>
          </StickyContainer>
        </LeftColumn>
        <RightColumn>{photo_content}</RightColumn>
      </ResumeCardRow>
    </ResumeCardContainer>
  );
}

export default PhotoGroup;
