import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PropertyAPI } from "../api";
import NavBar from "../components/Navbar";
import Property from "../types/PropertyType";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Container, Row, Column } from "../ui/layout";
import { Title } from "../styledTexts";
import { SubmitButton } from "../styledComponents";

const OwnerViewContainer = styled.div`
  margin-top: 50px;
  height: auto;
  margin-bottom: 50px;

  margin-left: 100px;
  margin-right: 100px;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const PropertyGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 20px;
  column-gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

// const PropertyCard = styled(Container)`
//   border: 1px solid black;

//   padding: 20px;
//   img {
//     object-fit: cover;
//   }
//   &:hover {
//     cursor: pointer;
//   }
// `;

const PropertyCard = styled(Container)`
  border: 1px solid black;
  padding: 20px;
  height: 200px;
  position: relative; // Needed for the overlay positioning
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
`;

// Overlay component to darken the background image
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); // Adjust the opacity to control brightness
`;

function OwnerView() {
  const [properties, setProperties] = useState<Property[]>([]);
  const { isLogged, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogged) {
      navigate("/login");
    }
  }, [isLogged, navigate]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const properties = await PropertyAPI.getProperties();
        setProperties(properties);
        // console.log("properties", properties);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    if (isLogged) {
      fetchProperties();
    }
  }, [isLogged]);

  const handleEditClick = (property: Property) => {
    navigate(`/owner-view/property/edit/${property._id}`, {
      state: { property },
    });
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <div>
      <NavBar />

      <OwnerViewContainer>
        <Title>Your Properties:</Title>
        <PropertyGrid>
          {properties.map((property) => (
            <PropertyCard
              key={property._id}
              onClick={() => handleEditClick(property)}
              style={{ backgroundImage: `url(${property.thumbnail})` }} // Set background image here
            >
              <Overlay /> {/* This will darken the image */}
              <Row>
                <h1 style={{ color: "white", zIndex: 1 }}>
                  {" "}
                  {/* Ensure text is visible */}
                  {property.address}
                </h1>
                {/* Remove the ImageContainer and img if you're setting the background on the PropertyCard */}
              </Row>
            </PropertyCard>
          ))}
        </PropertyGrid>
        {/* <Row style={{ justifyContent: "flex-end" }}>
          <SubmitButton style={{ width: "200px" }} onClick={handleLogout}>
            Logout
          </SubmitButton>
        </Row> */}
      </OwnerViewContainer>
    </div>
  );
}

export default OwnerView;
